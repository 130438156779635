import React , {useState} from "react";
import Bg from '../../Assets/secCtabg.webp'
import CtaFourImg from '../../Assets/CtaFourImg.webp'
import ButtonComponent from "../ButtonComponents/ButtonComponent";
import Modal from "../Modal/Modal";
const CtaFour = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <div className="relative bg-ctaBg"
            style={{
                backgroundImage: `url(${Bg})`,
                backgroundSize: 'cover', // This ensures the image covers the whole div
                backgroundPosition: 'center', // This centers the image
            }}>
            <div className=" panel  mt-24"

            >
                {/* Background gradient on top */}
                {/* <div className="absolute inset-0 bg-gradient-to-t from-lime-300 via-white/0 to-lime-300 h-2 rounded-t"></div> */}

                {/* Container for content */}
                <div className=" mx-auto flex flex-col md:flex-row items-center justify-between py-10 xl:py-12    z-10">
                    {/* Left side image */}
                    <div className=" md:block md:w-1/3 ">
                        <img src={CtaFourImg} alt="Brand left" className="absolute -top-28   md:top-0 md:bottom-5 mx-auto  left-0 max-w-[20rem] md:max-w-[20rem] lg:max-w-[25rem] lg:-top-20  xl:max-w-[30rem]" />
                    </div>

                    {/* Middle content */}
                    <div className="text-center md:w-full text-white space-y-4 mx-auto mt-52 md:mt-0">
                        <p className="secondaryText uppercase" style={{
                            letterSpacing: "5.6px"
                        }} >
                            Crush it
                            <span className="text-[#B5ED3F]"> in Search</span>
                        </p>
                        <h1 className="cta7xl">
                            Watch Your Rankings Soar
                            with our expertise
                        </h1>
                        <p className="primaryText capitalize">
                            Drop your Query!
                        </p>
                        <div className="mx-auto">
                            <ButtonComponent onOpen={openModal} />
                        </div>
                    </div>

                    {/* Right side image */}
                    {/* <div className="hidden md:block md:w-1/5 ">
                    <img src={SecRight} alt="Brand right" className="absolute bottom-0 right-10  mx-auto" />
                </div> */}
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} />

        </div>

    );
};

export default CtaFour;
