export const fontSizes = {
  navbarText: "xl:text-[16px] lg:text-[14px] md:text-[10px] fl:text-[18px]",
  primaryHeading: "xl:text-[70px] lg:text-7xl text-4xl md:text-6xl",
  base: "xl:text-[17px] lg:text-[16px] md:text-[14px] sm:text-[14px] fl:text-[14px]",
  text_xl:
    "xl:text-[20px] lg:text-[20px] md:text-[15px] sm:text-[15px] fl:text-[14px]",
  text_2_xl:
    "xl:text-[24px] lg:text-[22px] md:text-[18px] sm:text-[16px] fl:text-[13px]",
  text_3_xl:
    "xl:text-[28px] lg:text-[26px] md:text-[25px] sm:text-[20px] fl:text-[18px]",

  text_5_xl:
    "xl:text-[45px] lg:text-[37px]  md:text-[30px]   sm:text-[25px]  fl:text-[23px]",
  text_5P5_xl:
    "xl:text-[50px] lg:text-[40px] md:text-[30px] sm:text-[25px] fl:text-[25px]",

  text_6_xl:
    "xl:text-[60px] lg:text-[50px]  md:text-[40px]   sm:text-[30px]  fl:text-[25px]",
  text_4_xl:
    "xl:text-[35px] lg:text-[30px] md:text-[25px] sm:text-[20px] fl:text-[18px]",
  text_9_xl: "xl:text-9xl",
};

export const Constant = {
  CompanyName: "Search Bloom LLC",
  CompanyUrl: "searchbloomllc.com",
  CompanyUrlWithWWW: "www.searchbloomllc.com",
  CompanyUrlWithHHTPS: "https://www.searchbloomllc.com/",
  PhoneNumber: "(800) 978-1179",
  PhoneNumberLink: "tel:8009781179",
  Email: "info@searchbloomllc.com",
  EmailLink: "mailto:info@searchbloomllc.com",
  Address: "157 Church st 19th floor, New haven, CT 06510",
  FacebookLink: "https://www.facebook.com/BellevuePublisher",
  InstagramLink: "https://www.instagram.com/bellevuepublishers/ ",
  LinkedinLink: "https://www.linkedin.com/company/bellevue-publishers/",
  TwitterLink: "https://twitter.com/BellevuePublish",
  TrustPilotLink: "https://www.trustpilot.com/review/nydhub.com",
  WhatsAppLink: "tel:8009747242",
  GoogleReviewsLink:
    "https://www.google.com/search?q=bellevue+publishers&oq=bellevuepublishers&gs_lcrp=EgZjaHJvbWUqCQgCEAAYChiABDIGCAAQRRg5MgYIARBFGDwyCQgCEAAYChiABDIJCAMQABgKGIAEMgYIBBBFGDwyBggFEEUYPDIGCAYQRRg80gEJMTAwMTBqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x89e7d935a2265f6f:0x4108c6633bc8a125,1,,,,",
  REACT_APP_reCAPTCHA_SITE_KEY: "6LeLj3InAAAAAKu0MpTsQtHhDM2SRjY26CLxPDCG",
  REACT_APP_reCAPTCHA_SECRET_KEY: "6LeLj3InAAAAAJDHGPz7h1TknZq77KbI9AzY1yq9",
};
