import React , {useState} from "react";
import RedFlagImg from "../../Assets/RedFlag.webp";
import { fontSizes } from "../../Constants/Constant";
import ButtonComponent from "../ButtonComponents/ButtonComponent";
import Modal from "../Modal/Modal";
export default function HighBounce() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <div className="panel mx-auto lg:px-5 mt-10 `">
            {/* <div data-aos="fade-down" className="grid  md:grid-cols-2 text-start mb-5 md:mb-10 ">
        <div  className="flex items-center ">
        <h2 className={`${fontSizes.text_5P5_xl} font-extrabold p-5  md:leading-10  `}>
        Search Bloom – UNSURP the SERP Myths
        </h2>

        </div>
        <div className="border-s-2 border-[#032E32] p-5">
          <p
                      style={{ color: "rgba(0, 0, 0, 0.50)" }}
                      className={`${fontSizes.text_base} leading-[ 22.961px] tracking-[-0.318px]  `}
          
          >
         Unlike other SEO companies, at Search Bloom, we do not dwell on what is in popular trend but on what is actually effective. We employ reliable and simple SEO strategies that work and have lasting impacts in the market. They involve you directly in the development process, which is why you get a perfect solution that meets the needs of your business. This is why we give you straightforward, no-nonsense updates and easy-to-understand reports so you can see how your website is getting better. Whenever you need help, our support service is always friendly, courteous, and available to assist you. 
          </p>
        </div>
      </div> */}

            <div className=" mx-auto xl:p-6 grid md:grid-cols-2 gap-6">
                <div className="flex flex-col justify-center">
                    <div className="relative rounded-lg">
                        <img
                            src={RedFlagImg}
                            alt="Person with laptop"
                            className=""
                        />
                    </div>
                </div>

                <div  className="flex flex-col justify-center">
                    {/* Middle content */}
                    <div className="text-center md:w-full space-y-4 mx-auto">
                        <p className="secondaryText uppercase" style={{
                            letterSpacing: "2.8px"
                        }} >
                           Do you know about  <span className="text-[#AE0808]"> Red Flags</span> in SEO?
                            
                        </p>
                        <h1 className="cta9xl !text-[#2F2F2F] !font-extrabold">
                        HIGH BOUNCE
                        RATES!!!!
                        </h1>
                        <p className="baseText capitalize">
                        High bounce rates are like a red flag telling you that something is not right with the website. If there are people who are coming in and bailing out immediately without spending a single minute on what you have to offer, it is past time to find out why. No matter whether your website is loading too slowly or has an old design or platform that doesn’t provide the content users want, Search Bloom can help. We will find out what is causing the problem and SEO optimize your website so you get relevant visitors. Capture & track your traffic – don’t let it get lost, as traffic is the lifeblood of websites and blogs.
                        </p>
                        <div className="mx-auto">
                            <ButtonComponent onOpen={openModal} css={'!text-[#fff] hover:!text-[#000] !bg-[#000]'} />
                        </div>
                    </div>
                </div>

            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} />

        </div>
    );
}
