import React , {useState} from "react";
import Bg from '../../Assets/secCtabg.webp'
import CtaNineImg from '../../Assets/CtaNineImg.webp'
import ButtonComponent from "../ButtonComponents/ButtonComponent";
import Modal from "../Modal/Modal";
const CtaNine = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <div className="  bg-ctaBg relative mt-4"
            style={{
                backgroundImage: `url(${Bg})`,
                backgroundSize: 'cover', // This ensures the image covers the whole div
                backgroundPosition: 'center', // This centers the image
            }}
        >
            {/* Background gradient on top */}
            {/* <div className="absolute inset-0 bg-gradient-to-t from-lime-300 via-white/0 to-lime-300 h-2 rounded-t"></div> */}

            {/* Container for content */}
            <div className="panel lg:!py-5">
                <div className="mx-auto flex flex-col md:flex-row items-center justify-between  pb-5 md:pb-0     z-10">
                    {/* Left side image */}
                    <div className="flex justify-center items-center w-full md:w-1/2  ">
                        <img src={CtaNineImg} alt="Brand left" className="mx-auto  max-w-[14rem] md:max-w-[18rem] lg:max-w-[25rem]  " />
                    </div>

                    {/* Middle content */}
                    <div className="text-center md:w-full text-white space-y-4 mx-auto  md:pb-0">
                        <div className="cta7xl">
                            <span className="text-[30px]  sm:text-[45px] md:text-[60px] lg:text-[80px] xl:text-[100px] "  > Want </span>
                            <br />
                            Top  <span className="text-[#A1D335]"> Rankings?</span>
                        </div>
                        <p className="primaryText capitalize">
                            Hit this Chat Button, and <br /> Let’s Get Started!
                        </p>
                        <div className="mx-auto">
                            <ButtonComponent onOpen={openModal} />
                        </div>
                    </div>

                    {/* Right side image */}
                    {/* <div className="hidden md:block md:w-1/5 ">
                    <img src={SecRight} alt="Brand right" className="absolute bottom-0 right-10  mx-auto" />
                </div> */}
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} />

        </div>
    );
};

export default CtaNine;

