



import React from "react";
import { Collapse, theme, ConfigProvider } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { fontSizes } from "../../Constants/Constant";


const getItems = (panelStyle) => [
  {
    key: "1",
    label: "Visibility",
    children: (
      <p>{`Being on the first page is important because the majority of the users only search for results on the first page. `}</p>
    ),
    style: panelStyle,
  },
  {
    key: "2",
    label: "Traffic ",
    children: (
      <p>{`Search engine optimization techniques help to attract more specific visitors to your website, and thus, the chances of converting them are high.`}</p>
    ),
    style: panelStyle,
  },
  {
    key: "3",
    label: "Credibility ",
    children: (
      <p>{`Being at the top of the search allows the site to be more credible to the users as they will have confidence that the site is trustworthy.`}</p>
    ),
    style: panelStyle,
  },
  {
    key: "4",
    label: "Cost-Effectiveness",
    children: (
      <p>{`However, unlike paid ads campaigns, SEO is one of the most cost-effective marketing strategies because it targets users who are actively looking for your products and services online.`}</p>
    ),
    style: panelStyle,
  },
  // {
  //   key: "5",
  //   label: "Real-Time Customer Engagement: ",
  //   children: (
  //     <p>{`Want to connect with your customers right away? we set up live chat on your GMB profile so you can answer questions and keep customers happy. Stay in touch and make sure your customers are always smiling!`}</p>
  //   ),
  //   style: panelStyle,
  // },
];
function SecondFaq({ items }) {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: "#F8F8F8",
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  return (
    <div className="w-full flex justify-center items-center ">
      <div className="max-w-[1320px] w-full  flex  flex-col gap-4">
      
        <div  className="w-full holdOnFaq secondFaq">
          <ConfigProvider
            theme={{
              components: {
                Collapse: {
                  contentBg: "#fff",
                  headerBg: "#fff",
                },
              },
              token: {
                colorTextHeading: "#000000",
                colorText: "#000000",
              },
            }}
          >
            <Collapse
              className={`w-full ${fontSizes.base} !text-[#000000] !bg-transparent`}
              bordered={false}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined className="!text-[#000000] !text-lg" rotate={isActive ? 90 : 0} />
              )}
              
              items={items ? items : getItems(panelStyle)}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
}

export default SecondFaq;
