import React , {useState} from "react";
import Bg from '../../Assets/secCtabg.webp'
import CtaSixRight from '../../Assets/CtaSixRight.webp'
import ButtonComponent from "../ButtonComponents/ButtonComponent";
import Modal from "../Modal/Modal";
const CtaSix = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <div className="  lg:!py-2 bg-[#7aa129] relative mt-5 md:mt-10"
            style={{
                backgroundImage: `url(${Bg})`,
                backgroundSize: 'cover', // This ensures the image covers the whole div
                backgroundPosition: 'center', // This centers the image
            }}
        >
            <div className=" panel ml-auto flex flex-col md:flex-row items-center justify-start !py-5 md:!py-10 lg:py-20 xl:py-28 px-4 md:px-8  z-10">

                <div className="text-center      text-white space-y-4 pt-72 md:pt-0" >
                    <h1 className="cta7xl">
                        See What We Can Do
                    </h1>
                    <p className="primaryText">
                        Click Now and Connect with the <br className="hidden md:flex" /> Best in the Business!
                    </p>
                    {/* <div className="flex justify-center space-x-4"> */}
                    <ButtonComponent onOpen={openModal} css2={'!text-[#fff] !bg-[#A1D335]'} />
                    {/* </div> */}
                </div>

                {/* Right side image */}
                {/* <div className="flex justify-center items-center md:w-[30%] ">
                    <img src={CtaSixRight} alt="Brand right" className="mx-auto" />
                </div> */}
                <div className=" w-full md:w-1/3  flex justify-center">
                    <img src={CtaSixRight} alt="Brand left" className="absolute top-0  md:right-20 mx-auto max-w-[12rem] md:max-w-[11rem] lg:max-w-[16rem] xl:max-w-[16rem] " />
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} />

        </div>
    );
};

export default CtaSix;
