import React from "react";
import GhostwritingBottomImg from "../../Assets/GhostwritingBottom.webp";
import { fontSizes } from "../../Constants/Constant";
import Tick from "../../Assets/Tick.svg";
// import a from "next/a";
function GhostwritingService() {
  const services = [
    "Growth Low Rankings: Optimise the rankings of visited links",
    " Increase Traffic: Increase more traffic to your site.",
    "Fix Technical Problems: Understand the technological component related to SEO.",
    "Improve Content Quality: Help your content to be noticed",
    "Optimize for Conversions: : Zoom the possibility of turning visitors into customers.",
  ];
  return (
    <div
      className="w-full flex justify-center items-center  relative"
    // style={{
    //   backgroundImage: `url(${GhoshtBg.src})`,
    //   backgroundRepeat: "no-repeat",
    //   backgroundSize: "cover",
    // }}
    >
      <div className="panel text-start xl:flex-row lg:flex-row md:flex sm:flex-col fl:flex-col w-full justify-center items-start  ">
        <div
    
          className="xl:w-[50%] lg:w-[50%] md:w-[100%] sm:w-[100%] fl:w-[100%] relative "
        >
          <div className="px-3 sm:px-5 pt-5 pb-0  rounded-3xl col-span-2 flex flex-col justify-between bg-[#D9D9D9] text-center" >
            <div className="">
              <p

                className={`${fontSizes.text_2_xl} py-2  text-[#7AA129] uppercase `}
                style={{ letterSpacing: "1.45px" }}
              >
                Frustrated with SEO Results?
              </p>
              <div
                className={` !text-[#2F2F2F] !uppercase !font-extrabold  primaryHeading leading-[111%] `}
              >
                See How We Address <br />
                Common Challenges
              </div>
              <p className={` ${fontSizes.base} capitalize pt-3 text-[#000]`} >Tired of bad search engine optimization outcomes? We address most challenges directly with our effective strategies. Such problems as low rankings and, subsequently, low traffic are effectively solved by our expert team. Please rely on us to solve all your SEO problems and give you the desired outcomes. Don’t take the frustration to weigh Murphy down; let it motivate this program to achieve success</p>

            </div>
            <div className=" pt-[15rem] xl:pt-[33rem] flex justify-center items-center">
              <img src={GhostwritingBottomImg} alt="Review Second" className="absolute bottom-0 max-w-[14rem] sm:max-w-[16rem] xl:max-w-[35rem] " />
            </div>
          </div>
        </div>
        <div className="xl:w-[50%] h-full  lg:w-[50%] md:w-full sm:w-full fl:w-full  flex justify-center  items-center  ">
          <div  className=" ">
            {/* <h2 className={`${fontSizes.text_2_xl} leading-8 mb-2 font-light`}>
              <span
                className={`${fontSizes.text_6_xl} text-[#000000] font-extrabold `}
              >
                How SEO Will Benefit Your Brand?
              </span>
            </h2>
            <p
              className={`${fontSizes.base} tracking-[-0.318px] xl:font-medium lg:font-medium md:font-medium sm:font-semibold fl:font-semibold`}
            >
              Search Bloom, our SEO agency, helps your brand increase its online presence and get more quality traffic to your website. We will demonstrate to you the strategic SEO used in search rankings and how it helps to build up your business.
            </p> */}
            <div className=" py-5">
              <ul
                className={` ${fontSizes.text_xl} flex flex-col gap-5 h-full  list-none space-y-2 md:pl-4 fl:leading-5 sm:leading-8 xl:font-medium lg:font-medium md:font-medium sm:font-semibold fl:font-semibold`}
              >
                {services.map((service, index) => (
                  <div
                    style={{ border: "solid" }}
                    className="border flex items-center gap-2 rounded-full !border-[#f4f4f4]	border-solid  p1- md:p-2 hover:bg-[#122e32] hover:text-[#fff] transition-colors duration-500"
                  >
                    <img src={Tick} alt="" />
                    <li  className="" key={index}>
                      {service}
                    </li>
                  </div>
                ))}
              </ul>
            </div>
            {/* <div className=" ">
              <a href='/fiction-writing'>
                <button className="flex items-center px-8 py-3 pb-4 font-bold text-[16PX] text-[#000000] rounded-[25px] bg-[#CAE28B] hover:bg-[#E4BEFB] hover:text-[black]">
                  READ MORE
                </button>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GhostwritingService;
