import React, {useState} from "react";
import Bg from '../../Assets/secCtabg.webp'
import PersonImg from '../../Assets/personImg.webp'
import EightLeft from '../../Assets/EightLeft.webp'
import EightRight from '../../Assets/EightRight.webp'
import ButtonComponent from "../ButtonComponents/ButtonComponent";
import Modal from "../Modal/Modal";
const CtaEight = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <div className="bg-[#EEEEEE]  relative mt-24" >
            <div className="flex justify-center items-center">
                <img src={PersonImg} alt="Brand left" className="absolute -top-24 mx-auto  !max-w-[10rem] " />
            </div>

            <div className=" panel "
                style={{
                    backgroundImage: `url(${Bg})`,
                    backgroundSize: 'cover', // This ensures the image covers the whole div
                    backgroundPosition: 'center', // This centers the image
                }}
            >
                {/* Background gradient on top */}
                {/* <div className="absolute inset-0 bg-gradient-to-t from-lime-300 via-white/0 to-lime-300 h-2 rounded-t"></div> */}

                {/* Container for content */}
                <div className=" mx-auto flex flex-col md:flex-row items-center justify-between py-16 xl:py-12    z-10">
                    {/* Left side image */}
                    <div className="hidden md:block md:w-1/5 ">
                        <img src={EightLeft} alt="Brand left" className="absolute bottom-0 left-0 max-w-[16rem] lg:max-w-[20rem] xl:max-w-[24rem] " />
                    </div>

                    {/* Middle content */}
                    <div className="text-center md:w-full  space-y-4 mx-auto">
                        <p className="secondaryText font-medium uppercase !text-[#7AA129]" style={{
                            letterSpacing: "6.931px"
                        }} >
                            Ready for    a Big Leap?
                            {/* <span className="text-[#B5ED3F]"> in Search</span> */}
                        </p>
                        <h1 className="cta7xl !text-[#2F2F2F]">
                            Let’s optimize your <br />
                            website together
                        </h1>

                        <div className="flex justify-center space-x-4">
                          <ButtonComponent onOpen={openModal} css={'!text-[#fff] hover:!text-[#000] !bg-[#000]'} />
                        </div>
                    </div>

                    {/* Right side image */}
                    <div className="hidden md:block md:w-1/5  ">
                        <img src={EightRight} alt="Brand right" className="absolute bottom-0 right-0  max-w-[16rem] lg:max-w-[20rem] xl:max-w-[24rem]" />
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} />

        </div>
    );
};

export default CtaEight;
