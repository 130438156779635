


import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fontSizes } from '../../Constants/Constant';




const FormulaSection = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,

        customPaging: () => (
            <button className="custom-dot"></button>
        ),
        appendDots: dots => (
            <ul className="slick-dots">{dots}</ul>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    const cardData = [

        {
            id: 1,
            content: 'Initial Consultation & Audit: First, we understand your objectives and SEO issues. Second, we audit your web site to find out what needs to be done and how',
            title: 'Discovery Phase',

        },
        {
            id: 2,
            content: 'Custom SEO Strategy: We map out an accurate plan to the company’s requirements and the audit results that will be achieved.',
            title: 'Strategy Development',

        },
        {
            id: 3,
            content: 'Execution: To increase website ranking, we offer superior on-page, off-page and technical search engine optimization solutions.',
            title: 'Implementation',

        },
        {
            id: 4,
            content: 'Ongoing Monitoring: SEO outcome is constantly monitored while strategies for retaining and increasing rankings are adopted.',
            title: 'Monitoring and Optimization',

        },
        {
            id: 4,
            content: 'Detailed Reports: We provide concise and practical evaluation reports which demonstrate your performance and revenue in practice and, accordingly, do not leave room for ambiguity.',
            title: 'Reporting',

        },

    ];
    return (
        <section className="relative">
            <div className="panel mx-auto px-4 text-center "
            // style={{ background: "linear-gradient(220deg, #7AA129 7.26%, #A1D335 89.03%)" }}
            >
                {/* <h2
                    className=" text-3xl md:text-4xl lg:text-8xl absolute top-4 left-1/2 transform -translate-x-1/2 uppercase !font-extrabold text-transparent  opacity-30 font-sans"
                    style={{
                        background: "linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5))",
                        WebkitBackgroundClip: "text",
                        backgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        fontWeight: '900',
                    }}
                >
                    Testimonial
                </h2> */}
                <div className='flex flex-col md:flex-row justify-center items-center text-start !py-5 xl:!py-16'>

                    <h2 className={` flex-1 ${fontSizes.text_5P5_xl} font-bold  !text-[#2F2F2F] leading-[28px] md:leading-[40px] xl:leading-[55px]  `}>Get Found Faster with Our Genuis SEO Formula</h2>
                    <p className=" flex-1 SecondaryText text-[#000] mt-4 mb-8">
                        Forget the guesswork; our tried and tested SEO process delivers the results you deserve. Crisp and effectual procedures are employed to ensure the website visibility in the search engine rankings increases and more traffic is directed to the site. With us, your website will be ranked at the top and make you gain what you desire
                    </p>
                </div>

                <Slider {...settings}>
                    {cardData.map((card, index) => (
                        <div className="flex flex-col md:flex-row items-center justify-center gap-4 text-start ">
                            <div className="bg-[#D9D9D9] p-6 mx-2 max-w-md  !min-h-[300px] mb-4">
                                <div className={` font-bold ${fontSizes.text_3_xl} !text-[#2F2F2F] mb-4 leading-7`}>
                                    {card.title}

                                </div>
                                <p className={`text-[#2F2F2F] ${fontSizes.text_xl}`}>
                                    {card.content}
                                </p>
                                {/* <div className="flex items-center mt-4">
                                    <img src={card.image} alt="Riley Carter" className="w-12 h-12 rounded-full mr-3" />
                                    <div>
                                        <h4 className="text-gray-800 font-semibold">{card.name}</h4>
                                        <p className="text-gray-500 text-sm">Categories: 3D Modelling</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    ))}
                </Slider>


            </div>
        </section>
    );
};

export default FormulaSection;
