import React, { useState } from "react";
import { fontSizes } from "../../Constants/Constant";
import Modal from "../Modal/Modal";
function TestimonialSection({ titlePart1, titlePart2, description, services }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 2,
  //   autoplay: true,
  //   slidesToScroll: 1,
  //   vertical: true, // Enable vertical mode
  //   verticalSwiping: true, // Enable vertical swiping
  
  //   nextArrow: <></>,
  //   prevArrow: <></>,
  //   // customPaging: () => (
  //   //   <button className="custom-dot"></button>
  //   // ),
  //   // appendDots: dots => (
  //   //   <ul className="slick-dots">{dots}</ul>
  //   // ),
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         infinite: true,
  //       },
  //     },
  //     {
  //       breakpoint: 800,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  return (
    <div className="w-full lg:text-start flex  justify-center items-center relative ">
      <div className="panel flex w-full py-5 xl:gap-14 lg:gap-14 md:gap-14 sm:gap-2 fl:gap-2 xl:flex-row lg:flex-row md:flex-col sm:flex-col fl:flex-col">
        <div

          className=" md:pt-10 lg:pt-0 xl:w-[45%] lg:w-[50%] md:w-[80%] sm:w-[100%] fl:w-[100%] justify-center   mx-auto items-center lg:items-start flex flex-col  gap-5 lg:gap-10 "
        >
          <h1
            className={` font-bold ${fontSizes.primaryHeading} leading-[40px] text-start  `}
          >
            Testimonials
          </h1>
          <h2 className={`${fontSizes.text_5P5_xl}  !font-normal leading-[28px] lg:leading-[42px] xl:leading-[55px]`}>
            Why Us ? <br /> Find Out What Our Clientele Says
          </h2>
          <p
            style={{ color: "rgba(0, 0, 0, 0.50)" }}
            className={`${fontSizes.text_base} leading-[ 22.961px] tracking-[-0.318px]  `}
          >
            {description}
          </p>
          <div className="flex flex-col sm:flex-row  justify-center lg:justify-start gap-5 w-full items-center">
            <button onClick={openModal} className="bg-lime-500 text-white py-2 px-4 rounded-md hover:bg-lime-600">
              Get A Quote
            </button>
          </div>
        </div>
        <div

          className="xl:w-[55%] lg:w-[50%] md:w-[100%] sm:w-[100%] fl:w-[100%]  flex justify-center gap-5 flex-col"
        >
          <div className="flex flex-col gap-4 py-8 ">
            {/* <Slider {...settings}> */}
              {services?.map((service, index) => (
                <div key={index} className="p-4 bg-[#F7F7F7] rounded-xl relative">
                  <div className={`${index === 1 ? "bg-[#B5ED3F] " : "  bg-[#BEBEBE]"}  p-[3px] absolute top-0 left-0 h-full rounded-xl`}></div>
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-col sm:flex-row justify-start items-center gap-2">
                      <img
                        className="w-[100px]"
                        src={service.image}
                        alt="brand-promotion"
                      />
                      <div>
                        <h4 className={`text-[#000] ${fontSizes.text_xl} font-semibold`} >{service.name}</h4>
                        <p className={`${fontSizes.base} text-[#312D2D] font-light leading-6`}>
                          {service.description}
                          {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {/* </Slider> */}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} />

    </div>
  );
}

export default TestimonialSection;
