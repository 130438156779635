// import React from "react";
// import { fontSizes } from "../../Constants/Constant";
// import ButtonComponent from "../ButtonComponents/ButtonComponent";
// import ReviewSecondImg from '../../Assets/reviewSecond.webp'
// import ReviewImg from '../../Assets/ReviewMap.webp'
// // import cardImg from "../../assets/reviewCard.webp";
// // import reviewImg from "../../assets/reviewimg.webp";
// // import heartImg from "../../assets/heart.webp";
// export default function ReviewSection() {
//   return (
//     <div className="flex justify-center items-center mt-10 md:mt-5">
//       <div className="panel rounded overflow-hidden  mx-auto text-start">

//         <div className="md:grid fl:flex fl:flex-col fl:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 !gap-y-4 md:gap-5">

//           <div className="px-5 pt-5 pb-0  rounded-3xl col-span-2 flex flex-col justify-between" style={
//             {
//               background: "linear-gradient(220deg, #7AA129 7.26%, #A1D335 89.03%)"
//             }
//           } >
//             <div className=" pb-5 md:p-5">
//               <p

//                 className={`${fontSizes.text_3_xl} py-2  text-[#fff] `}
//                 style={{ letterSpacing: "7.42px" }}
//               >
//                 But Wait First
//               </p>
//               <div
//                 className={`text-[#fff] uppercase font-semibold md:font-extrabold  ${fontSizes.text_5P5_xl} leading-[111%] `}
//               >
//                 Pinpoint the Right SEO Strategy for Your Business
//               </div>


//             </div>
//             <img src={ReviewImg} alt="Review Second" className="md:max-w-[32rem] lg:max-w-[34rem] fl:max-w-[19rem] mx-auto" />
//           </div>
//           <div className=" !bg-[#2F2F2F] rounded-3xl flex flex-col justify-between">
//             <div className="p-5">
//               <div
//                 className={`text-[#fff] relative uppercase font-bold leading-5 md:leading-7 mb-2 ml-5 ${fontSizes.text_4_xl} before:content-['•'] before:absolute before:-left-4`}

//               // className={`text-[#fff]  uppercase font-bold leading-5 md:leading-7 mb-2 ml-5 ${fontSizes.text_4_xl} before:content-['•'] before:absolute before:-left-4`}
//               >
//                 Local SEO
//               </div>
//               <p
//                 className={`${fontSizes.base} py-2  leading-[18px] text-[#fff] `}
//               >
//                 How We Help: Local SEO is completely about attracting more customers locally. We optimize your website and Google My Business page so that they rank in local search results. They are based on the geographical area of your business, plus we ensure your information is correctly updated everywhere.
//               </p>
//               <p
//                 className={`${fontSizes.base} py-2  leading-[18px] text-[#fff] `}
//               >
//                 Why It’s Good for You: Local SEO helps your business to be found by people within the vicinity or those who are searching for a business within your area of operation, whether you operate an offline business or have a physical shop. This means more of the local people and visitors are out for services or products within their area of preference.
//               </p>
//               <div className="pt-5 flex flex-col ">
//                 <ButtonComponent />
//               </div>
//             </div>

//             <img src={ReviewSecondImg} alt="Review Second " />

//           </div>
//           <div className="  h-full  md:flex flex-col justify-between gap-5 space-y-10" >

//             <div className="p-3 bg-[#EDEEEB] rounded-3xl "
//               style={{
//                 boxShadow: "10px 12px 35.2px 0px rgba(0, 0, 0, 0.25)"
//               }}
//             >
//               <div
//                 className={`text-[#000] relative uppercase font-bold leading-5 md:leading-7 mb-2 ml-5 ${fontSizes.text_4_xl} before:content-['•'] before:absolute before:-left-4`}
//               // className={` capitalize font-semibold leading-5 ${fontSizes.text_4_xl}  `}
//               >
//                 Nationwide SEO
//               </div>
//               <div className="py-2">
//                 <p
//                   style={{ color: "rgba(0, 0, 0, 0.50)" }}
//                   className={`${fontSizes.base}   leading-[18px] `}
//                 >
//                   How We Help: Nationwide SEO is ideal for occasions where you need to capture your customers across the country. Search for broad keywords that people type in the search engine at any point across the country and post content that is interesting to many individuals. We also establish links between other trusted sites in order to help improve the ranking of your site in the search engine.

//                 </p>
//                 <p
//                   style={{ color: "rgba(0, 0, 0, 0.50)" }}
//                   className={`${fontSizes.base}   leading-[18px] `}
//                 >
//                   Why It’s Good for You: To the companies operating on the internet or having a large client base in the country, Nationwide SEO will get you customers from across the nation. It makes you exposed, and it brings more traffic and likely buyers across the nation
//                 </p>

//               </div>
//             </div>
//             <div className="p-3 !text-[#fff] rounded-3xl "
//               style={{ background: "linear-gradient(220deg, #7AA129 7.26%, #A1D335 89.03%)" }}
//             >
//               {/* <div
//                 className={` uppercase font-bold  leading-5 ${fontSizes.text_4_xl}  `}
//               >
//                 Global SEO
//               </div> */}

//               <div
//                 className={`relative uppercase font-bold leading-5 md:leading-7 mb-2 ml-5 ${fontSizes.text_4_xl} before:content-['•'] before:absolute before:-left-4`}
//               >
//                 Global SEO
//               </div>


//               <div className="capitalize">
//                 <p

//                   className={` baseText !text-[#fff] py-2  leading-[18px] `}
//                 >
//                   How We Help: Global SEO is for companies that are aiming at customers in other countries. To ensure your site functions properly across different languages and regions, we use HREFLANG TAGS to help the right consumers see the right content. We also localize and adapt your content, to the culture you are targeting as well.
//                 </p>
//                 <p

//                   className={`baseText !text-[#fff] py-2  leading-[18px] `}
//                 >

//                   Why It’s Good for You: If you wish to reach a different nation’s inhabitants, Global SEO makes your website accessible to people in different countries. This makes it easier for you to get to new customers in other countries which increases your operations in various countries.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
import React from "react";
import { fontSizes } from "../../Constants/Constant";
import ButtonComponent from "../ButtonComponents/ButtonComponent";
import ReviewSecondImg from '../../Assets/reviewSecond.webp'
import ReviewImg from '../../Assets/reviewAbsolute.webp'
// import cardImg from "../../assets/reviewCard.webp";
// import reviewImg from "../../assets/reviewimg.webp";
// import heartImg from "../../assets/heart.webp";
export default function ReviewSection() {
  return (
    <div className="flex justify-center items-center mt-10 md:mt-0 relative">
      
      <img src={ReviewImg} alt="Brand left" className="hidden lg:block absolute bottom-10 right-0 mx-auto max-w-[20rem] lg:max-w-[26rem] xl:max-w-[30rem]  " />

      <div className="panel rounded overflow-hidden  mx-auto text-start">



        {/* <div className="px-5 pt-5 pb-0  rounded-3xl col-span-2 flex flex-col justify-between" style={
            {
              background: "linear-gradient(220deg, #7AA129 7.26%, #A1D335 89.03%)"
            }
          } >
            <div className=" pb-5 md:p-5">
             


            </div>
            <img src={ReviewImg} alt="Review Second" className="md:max-w-[32rem] lg:max-w-[34rem] fl:max-w-[19rem] mx-auto" />
          </div> */}
        <div className='   grid grid-cols-1 md:grid-cols-2 text-start !py-5 md:!py-5 gap-5'>

          <div>
            <p

              className={`${fontSizes.text_3_xl} py-2  text-[#000] `}
              style={{ letterSpacing: "7.42px" }}
            >
              But Wait First
            </p>
            <div
              className={`text-[#000] uppercase font-semibold md:font-extrabold  ${fontSizes.text_5P5_xl} leading-[111%] `}
            >
              Pinpoint the Right SEO Strategy for Your Business
            </div>
          </div>
          <div className=" ">
            <img src={ReviewSecondImg} className="mx-auto" alt="Review Second " />

          </div>
        </div>
        <div className="lg:max-w-[80%] flex flex-col gap-5">


          <div className="  grid grid-cols-1 md:grid-cols-2 h-full gap-5" >

            <div className="p-3 bg-[#EDEEEB] rounded-3xl  h-full"
              style={{
                boxShadow: "10px 12px 35.2px 0px rgba(0, 0, 0, 0.25)"
              }}
            >
              <div
                className={`text-[#000] relative uppercase font-bold leading-5 md:leading-7 mb-2 ml-5 ${fontSizes.text_4_xl} before:content-['•'] before:absolute before:-left-4`}
              // className={` capitalize font-semibold leading-5 ${fontSizes.text_4_xl}  `}
              >
                Nationwide SEO
              </div>
              <div className="py-2 capitalize">
                <p
                  style={{ color: "rgba(0, 0, 0, 0.50)" }}
                  className={`${fontSizes.base}  py-2 leading-[18px] `}
                >
                  How We Help: Nationwide SEO is ideal for occasions where you need to capture your customers across the country. Search for broad keywords that people type in the search engine at any point across the country and post content that is interesting to many individuals. We also establish links between other trusted sites in order to help improve the ranking of your site in the search engine.

                </p>
                <p
                  style={{ color: "rgba(0, 0, 0, 0.50)" }}
                  className={`${fontSizes.base} py-2  leading-[18px] `}
                >
                  Why It’s Good for You: To the companies operating on the internet or having a large client base in the country, Nationwide SEO will get you customers from across the nation. It makes you exposed, and it brings more traffic and likely buyers across the nation
                </p>

              </div>
            </div>
            <div className="p-3 !text-[#fff] rounded-3xl h-full "
              style={{ background: "linear-gradient(220deg, #7AA129 7.26%, #A1D335 89.03%)" }}
            >
              <div
                className={`relative uppercase font-bold leading-5 md:leading-7 mb-2 ml-5 ${fontSizes.text_4_xl} before:content-['•'] before:absolute before:-left-4`}
              >
                Global SEO
              </div>
              <div className="py-2 capitalize">
                <p

                  className={` ${fontSizes.base} !text-[#fff] py-2  leading-[18px] `}
                >
                  How We Help: Global SEO is for companies that are aiming at customers in other countries. To ensure your site functions properly across different languages and regions, we use HREFLANG TAGS to help the right consumers see the right content. We also localize and adapt your content, to the culture you are targeting as well.
                </p>
                <p

                  className={`${fontSizes.base} !text-[#fff] py-2  leading-[18px] `}
                >

                  Why It’s Good for You: If you wish to reach a different nation’s inhabitants, Global SEO makes your website accessible to people in different countries. This makes it easier for you to get to new customers in other countries which increases your operations in various countries.
                </p>
              </div>
            </div>
          </div>
          <div className=" !bg-[#2F2F2F] rounded-3xl flex flex-col justify-between ">
            <div className="p-5 lg:max-w-[80%]">
              <div
                className={`text-[#fff] relative uppercase font-bold leading-5 md:leading-7 mb-2 ml-5 ${fontSizes.text_4_xl} before:content-['•'] before:absolute before:-left-4`}

              // className={`text-[#fff]  uppercase font-bold leading-5 md:leading-7 mb-2 ml-5 ${fontSizes.text_4_xl} before:content-['•'] before:absolute before:-left-4`}
              >
                Local SEO
              </div>
              <p
                className={`${fontSizes.base} py-2  leading-[18px] text-[#fff] `}
              >
                How We Help: Local SEO is completely about attracting more customers locally. We optimize your website and Google My Business page so that they rank in local search results. They are based on the geographical area of your business, plus we ensure your information is correctly updated everywhere.
              </p>
              <p
                className={`${fontSizes.base} py-2  leading-[18px] text-[#fff] `}
              >
                Why It’s Good for You: Local SEO helps your business to be found by people within the vicinity or those who are searching for a business within your area of operation, whether you operate an offline business or have a physical shop. This means more of the local people and visitors are out for services or products within their area of preference.
              </p>
              <div className="pt-5">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
