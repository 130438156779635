import React, {useEffect , useState} from "react";
import "./Header.css";
import Logo from "../../Assets/logo.webp";
import { Constant, fontSizes } from "../../Constants/Constant";
import { FaPhone } from "react-icons/fa6";
import { FiMessageCircle } from "react-icons/fi";
const Header = () => {
    // State to track if the page has been scrolled
    const [isScrolled, setIsScrolled] = useState(false);

    // Function to handle scroll event
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      // Set 'isScrolled' to true if the user scrolls more than 50px
      setIsScrolled(scrollTop > 50);
    };
  
    // Add event listener to detect scroll
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      // Cleanup the event listener when component unmounts
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  return (
    // <div className="header-wrapper bg-[#ffffff5d] backdrop-blur-xl fixed top-5 ">
    <div  className={` fixed top-0 pt-5 left-0 right-0 z-50 ${
      isScrolled ? 'bg-[#ffffff5d] backdrop-blur-xl !pt-0' : ''
    }`}>
      <div className=" panel nav-1  mx-auto ">
        <div className="logo-1">
          <img
            className="logo-nav"
            src={Logo}
            width="130"
            height="83"
            alt="1"
          />
          <div class="res-show">
            <a class="btn phone-number" href="/">
              {" "}
              <i
                class="fa fa-phone fa-heart fa-beat"
                aria-hidden="true"
                style={{ "--fa-animation-duration": "0.5s" }}
              ></i>
            </a>
          </div>
        </div>

        <div className="header-right">
          <div  class="btnNubWrap mob flex gap-5">
            <div className="  md:min-w-[250px] tracking-[1px] uppercase secondaryText !font-extrabold bg-primary !text-[#fff]  px-2 md:px-4 py-2 rounded-2xl hover:bg-[#fff] border-2 border-primary hover:!text-[#000] transition-colors">
              <a
                
                class="btn phone-number flex items-center justify-center  gap-2 "
                href={Constant.PhoneNumberLink}
              >

                <FaPhone className="animate-bounce" />

                <span >{Constant.PhoneNumber}</span>
              </a>
            </div>
            {/* <div style={{ background: "#032E32" }}  class="hero-btn display-none hover:opacity-75  transition-opacity duration-300">
              <a
                style={{ color: "#fff" }}
                class="btn phone-number flex items-center justify-center  gap-2 "
                href={Constant.PhoneNumberLink}
              >
                
                <FaPhone className="animate-bounce" />

                <span >{Constant.PhoneNumber}</span>
              </a>
            </div> */}
            {/* <a
              style={{ textDecoration: "none",  }}
              class="hero-btn live-chat !fl:p-5 !flex text-[#000] !justify-center items-center gap-2 btn-9 hover:!text-white" 
            >
              <FiMessageCircle className={`${fontSizes.text_xl} animate-bounce`} />
               <span>Start a Live Chat</span> 
            </a> */}
            <a
              style={{ textDecoration: "none", }}
              className="hidden  min-w-[250px] tracking-[1px] uppercase secondaryText !font-extrabold bg-[#172A39] !text-[#fff] px-4 py-2 rounded-2xl hover:bg-[#fff] border-2  hover:!text-[#000] hover:border-[#172A39] border-[#172A39] transition-colors md:flex items-center justify-center gap-2"
            >
              <FiMessageCircle className={`${fontSizes.text_xl} animate-bounce`} />
              <span>Start a Live Chat</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    //  </div>
  );
};

export default Header;
