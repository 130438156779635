import React from 'react';
import './button.css'
import { fontSizes } from '../../Constants/Constant';
const ButtonComponent = ({ onOpen, css, css2 }) => {



  return (
    <div className="flex flex-wrap  gap-2 justify-center  items-center  space-x-2 mx-auto">
      {/* <button onClick={onOpen} className="bg-white text-gray-800 py-2 px-4 rounded-md hover:bg-gray-100">
        Chat Now
      </button>
      <button className="bg-lime-500 text-white py-2 px-4 rounded-md hover:bg-lime-600">
        Get A Quote
        </button> */}
      <button className={`animated-button1 py-3 px-12 ${fontSizes.text_xl} ${css}`}>
        <span>Chat Now</span>
        <span></span>
      </button>
      <button onClick={onOpen} className={`animated-button py-3 px-12  ${fontSizes.text_xl} ${css2}`}>
        <span>Get Quote</span>
        <span></span>
      </button>
    </div>
  )
}

export default ButtonComponent;

{/* <div
  className="ModalOverlay-module--root--973eb ClearbitPopup-module--root--e69d1 ModalOverlay-module--enterDone--b4695"
  style={{ display: 'none', placeContent: 'center' }}
>
  <div
    className="Modal-module--content--08c43 ModalOverlay-module--content--6fd3a Modal-module--sizeMedium--c7dff ClearbitPopup-module--content--54005"
    tabIndex="-1"
  >
    <div className="popUpForm">
      <div>
        <img
          className="discountMobileImg"
          alt="popup-image"
          src="/assets/text-mobile.webp"
        />
      </div>
      <div>
        <img
          className="discountImg"
          alt="popup-image"
          src="/assets/TEXT.webp"
        />
      </div>

      <div className="ClearbitPopup-module--content__inner--5bfb5">
        <button
          type="button"
          className="Button-module--root--c1f31 Button-module--size_medium--04b3d Button-module--appearance_icon--32e72 ClearbitPopup-module--closeIconButton--ecc1d"
        >
          <span className="Icon-module--root--cd36a" tabIndex="-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              className="Icon-module--icon--600d8 Icon-module--small--a9dce"
              focusable="false"
            >
              <path
                d="M4.6 27.4c.8.8 2 .8 2.8 0l8.6-8.6 8.6 8.6c.8.8 2 .8 2.8 0s.8-2 0-2.8L18.8 16l8.6-8.6c.8-.8.8-2 0-2.8s-2-.8-2.8 0L16 13.2 7.4 4.6c-.8-.8-2-.8-2.8 0s-.8 2 0 2.8l8.6 8.6-8.6 8.6c-.8.8-.8 2 0 2.8z"
              />
            </svg>
          </span>
        </button>
        <div>
          <form id="form3" className="form-container">
            <div className="Heading-module--root--88ae8 Heading-module--responsive--c2052 Heading-module--removeLineBreakOnMobile--27e49 Heading-module--size_2--fffa8 Heading-module--font_heading--bc3b4 Heading-module--weight_extra_bold--16661 Header-module--title--00d76">
              <span>Contact Us</span>
            </div>
            <div className="form-line">
              <input name="name" type="text" className="form-input" required />
              <label>Name</label>
            </div>
            <div className="form-line">
              <input name="email" type="text" className="form-input" required />
              <label>Your email *</label>
            </div>
            <div className="form-line">
              <input name="phoneNumber" type="tel" className="form-input" maxLength="13" minLength="9" required />
              <label>Phone</label>
            </div>
            <div className="form-line">
              <input name="website" type="text" className="form-input" required />
              <label>Website</label>
            </div>

            <input
              type="submit"
              className="Button-module--root--c1f31 Button-module--size_small--cd324 Button-module--appearance_primary--7c32c Button-module--upperCase--be36b"
              style={{ marginTop: '20px' }}
              value="Submit"
            />
          </form>
        </div>
      </div>
    </div>

    <div
      data-gatsby-image-wrapper=""
      className="gatsby-image-wrapper gatsby-image-wrapper-constrained ClearbitPopup-module--backgroundIllustration--13a24"
    >
      <div style={{ maxWidth: '999px', display: 'block' }}>
        <img
          alt="popup-image"
          src="/assets/BG-DESKTOP.webp"
          style={{ maxWidth: '100%', display: 'block', position: 'static' }}
        />
      </div>
    </div>
  </div>
</div> */}

{/* {isModalOpen && (
        <div
          className="ModalOverlay-module--root--973eb ClearbitPopup-module--root--e69d1 ModalOverlay-module--enterDone--b4695"
          style={{ display: 'flex', placeContent: 'center' }}
        >
          <div
            className="Modal-module--content--08c43 ModalOverlay-module--content--6fd3a Modal-module--sizeMedium--c7dff ClearbitPopup-module--content--54005"
            tabIndex="-1"
          >
            <div className="popUpForm">
              <div>
                <img
                  className="discountMobileImg"
                  alt="popup-image"
                  src="/assets/text-mobile.webp"
                />
              </div>
              <div>
                <img
                  className="discountImg"
                  alt="popup-image"
                  src="/assets/TEXT.webp"
                />
              </div>

              <div className="ClearbitPopup-module--content__inner--5bfb5">
                <button
                  type="button"
                  onClick={closeModal}
                  className="Button-module--root--c1f31 Button-module--size_medium--04b3d Button-module--appearance_icon--32e72 ClearbitPopup-module--closeIconButton--ecc1d"
                >
                  <span className="Icon-module--root--cd36a" tabIndex="-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32"
                      className="Icon-module--icon--600d8 Icon-module--small--a9dce"
                      focusable="false"
                    >
                      <path
                        d="M4.6 27.4c.8.8 2 .8 2.8 0l8.6-8.6 8.6 8.6c.8.8 2 .8 2.8 0s.8-2 0-2.8L18.8 16l8.6-8.6c.8-.8.8-2 0-2.8s-2-.8-2.8 0L16 13.2 7.4 4.6c-.8-.8-2-.8-2.8 0s-.8 2 0 2.8l8.6 8.6-8.6 8.6c-.8.8-.8 2 0 2.8z"
                      />
                    </svg>
                  </span>
                </button>
                <div>
                  <form id="form3" className="form-container">
                    <div className="Heading-module--root--88ae8 Heading-module--responsive--c2052 Heading-module--removeLineBreakOnMobile--27e49 Heading-module--size_2--fffa8 Heading-module--font_heading--bc3b4 Heading-module--weight_extra_bold--16661 Header-module--title--00d76">
                      <span>Contact Us</span>
                    </div>
                    <div className="form-line">
                      <input name="name" type="text" className="form-input" required />
                      <label>Name</label>
                    </div>
                    <div className="form-line">
                      <input name="email" type="text" className="form-input" required />
                      <label>Your email *</label>
                    </div>
                    <div className="form-line">
                      <input name="phoneNumber" type="tel" className="form-input" maxLength="13" minLength="9" required />
                      <label>Phone</label>
                    </div>
                    <div className="form-line">
                      <input name="website" type="text" className="form-input" required />
                      <label>Website</label>
                    </div>

                    <input
                      type="submit"
                      className="Button-module--root--c1f31 Button-module--size_small--cd324 Button-module--appearance_primary--7c32c Button-module--upperCase--be36b"
                      style={{ marginTop: '20px' }}
                      value="Submit"
                    />
                  </form>
                </div>
              </div>
            </div>

            <div
              data-gatsby-image-wrapper=""
              className="gatsby-image-wrapper gatsby-image-wrapper-constrained ClearbitPopup-module--backgroundIllustration--13a24"
            >
              <div style={{ maxWidth: '999px', display: 'block' }}>
                <img
                  alt="popup-image"
                  src="/assets/BG-DESKTOP.webp"
                  style={{ maxWidth: '100%', display: 'block', position: 'static' }}
                />
              </div>
            </div>
          </div>
        </div>
      )} */}







{/* <script>
    const form3 = document.getElementById('form3');

    form3.addEventListener('submit', async (event) => {
      event.preventDefault();

      const formData = new FormData(form3);
      formData.append('fullPageUrl', window.location.href);
      formData.append('companyName', 'Search Bloomllc');  // Replace 'YourCompanyName' with actual company name

      const object = {};
      formData.forEach((value, key) => {
        object[key] = value;
      });

      try {
        const response = await fetch('https://americanseohub.com/api/v1/registerUserSearchBloom   ', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(object),
        });

        if (response.ok) {
          form3.reset();
          window.location.href = 'https://searchbloomllc.com/thankyou/';
        } else {
          alert('Form submission failed: ' + (response.statusText || 'Unknown error'));
          console.error('API error:', response.status, response.statusText);
        }
      } catch (error) {
        alert('Form submission failed: ' + error.message);
        console.error('Fetch error:', error);
      }
    });
  </script> */}


{/* <button className=" min-w-[250px] px-4 py-2 rounded-md transition">
        <span>Get A Quote</span>
      </button>
      <button className=" min-w-[250px] px-4 py-2 rounded-md  transition">
        <span> Live Chat </span>
      </button> */}