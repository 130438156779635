import React from "react";
import LeftBg from "../../Assets/HeroBg.webp";
import { Form, Input } from "antd";
import { Constant, fontSizes } from "../../Constants/Constant";
function HeroSection() {
  const onFinish = async (values) => {
    try {
      let submitDetail = { ...values };
      submitDetail.fullPageUrl = window.location.href;
      submitDetail.companyName = Constant.CompanyName;
      const response = await fetch(
        "https://americanseohub.com/api/v1/registerUserSearchBloom",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(submitDetail),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        window.location.href=(`${Constant.CompanyUrlWithHHTPS}/thankyou`);
        console.log("API response:", responseData);
      } else {
        console.error("API error:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred while making the API call:", error);
    }
  };
  return (
    <>
      <div className="w-full flex justify-center items-center relative   "
        style={{
          background: `url(${LeftBg}) no-repeat bottom left `,
          backgroundSize: 'cover',
          backgroundPosition: 'left',
      }}
      >

        {/* <img
          className="  absolute  lg:h-full bottom-0 left-0 w-[60%] h-full"
          src={LeftBg}
          alt="how"
        /> */}
        <div className="panel flex w-full  gap-5  md:gap-10 xl:gap-14 sm:flex-col fl:flex-col md:flex-row !pt-28 md:!pt-32 text-start">

          <div
            // data-aos="fade-down"
            className="xl:w-[60%] lg:w-[55%] md:w-[45%] sm:w-[100%] fl:w-[100%] flex justify-center  flex-col md:py-8 "
          >
            {/* <img
              className="  absolute  lg:h-full bottom-0 left-0 w-[100%] object-cover object-left md:w-[60%] h-full -z-40"
              src={LeftBg}
              alt="how"
            /> */}
            <h2
              className={`${fontSizes.text_5_xl}  text-[#fff]   capitalize font-bold leading-[28px] md:leading-[40px] xl:leading-[65px]  w-[100%] lg:w-[80%] xl:w-[75%] `}
            >
              Achieve High Search Rankings and Substitute Growth<span className="font-normal"> with our SEO Service </span>
            </h2>
           
            <ul className={`text-[#fff] list-disc ${fontSizes.text_3_xl} ml-3 md:ml-6 font-medium  my-5  `}>
              <li>
                Lead in SERPs
              </li>
              <li>
                Attract Target Traffic
              </li>
              <li>Maximize Your ROI</li>
              <li>Increase Website Clicks</li>
              <li>Optimize Search Performance</li>
              <li>Improve Search Visibility</li>
            </ul>
            {/* <div className="btn-11">
                button
            </div> */}
            {/* <div className="flex flex-col sm:flex-row  justify-center md:justify-start gap-5 w-full items-center pt-4">
              <CtaButtons
                styles={
                  "font-semibold bg-[#032E32]  text-[#fff]  lg:text-md px-6 lg:px-8 pt-1 pb-2 rounded-md border-2 border-[#032E32] py-3"
                }
              />
            </div> */}
            {/* <div>
              <div className="flex flex-wrap gap-x-1 gap-y-5 mx-auto  justify-center md:justify-between items-center py-10">
                <div className="text-center">
                  <div className=" flex gap-2 items-center justify-center ">
                    <img className="w-[35px]" src={HeroIcon1} alt="" />
                    <div
                      className={`${fontSizes.text_6_xl} flex text-[#000] w-full items-center gap-5 font-extrabold`}
                    >
                      1800
                    </div>
                  </div>
                  <p className="capitalize text-xs" >clients Working with us globally</p>
                </div>
                <div className="text-center md:border-r-2 md:border-l-2 md:border-[#000] px-2">
                  <div className="  flex gap-2 items-baseline justify-center">
                    <img className="w-[35px]" src={HeroIcon2} alt="" />
                    <div
                      className={`${fontSizes.text_6_xl} flex text-[#000] w-full items-center gap-5 font-extrabold`}
                    >
                      3.5MIL+
                    </div>
                  </div>
                  <p className="capitalize text-xs" >qualified leads delivered</p>
                </div>
                <div className="text-center">
                  <div className="  flex gap-2 items-baseline justify-center">
                    <img className="w-[35px]" src={HeroIcon3} alt="" />
                    <div
                      className={`${fontSizes.text_6_xl} flex text-[#000] w-full items-center gap-5 font-extrabold`}
                    >
                      89%
                    </div>
                  </div>
                  <p className="capitalize text-xs" >clients retantion rate</p>
                </div>
            
                
              </div>
            </div> */}
          </div>
          <div className=" xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] fl:w-[100%] justify-center items-center flex  fl:relative lg:static overflow-hidden">
            {/* <img
              className=" xl:absolute lg:absolute absolute  lg:h-full top-0 right-0"
              src={RightBg}
              alt="how-its-started"
            /> */}
            <div

              className=" flex justify-center w-full lg:w-[455px] md:my-5 fl:my-0 fl:px-0 md:px-5 lg:px-5 md:py-8  md:mx-5 items-center	  text-start  "
              // style={{
              //   boxShadow: "24px 22px 50px 0px rgba(0, 0, 0, 0.25)",
              //   background: "rgba(255, 255, 255, 0.90)",
              // }}
            >
              <Form
                name="contact_form"
                className="flex flex-col w-full h-full   justify-between  "
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                {/* <div>
                  <h2 className={`${fontSizes.text_2_xl} font-extrabold leading-6 `}>
                  Attract More Visitors – Get More Leads
                
                  </h2>
                  <br />
                  <p className={`text-[#575F6E] capitalize ${fontSizes.base}`}>
                  Get your free website audit and marketing strategy today!                  </p>
                </div> */}
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Name!",
                    },
                  ]}
                >
                  <Input
                    className=" h-14 placeholder:text-[#000000] bg-[#D9D9D9] text-[#000] rounded-lg"
                    placeholder="Name"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input
                    className=" h-14 placeholder:text-[#000000] bg-[#D9D9D9] text-[#000] rounded-lg"
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      type: "phone",
                      message: "The input is not valid Phone!",
                    },
                    {
                      required: true,
                      message: "Please input your Phone!",
                    },
                  ]}
                >
                  <Input
                    minLength={9}
                    maxLength={13}
                    className=" h-14 placeholder:text-[#000000] bg-[#D9D9D9] text-[#000] rounded-lg"
                    placeholder="Phone Number"
                  />
                </Form.Item>

                <Form.Item
                  name="website"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Website Url!",
                    },
                  ]}
                >
                  <Input
                    className=" h-14 placeholder:text-[#000000] bg-[#D9D9D9] text-[#000] rounded-lg"
                    placeholder="Website"
                  />
                </Form.Item>
                <Form.Item>
                  <div className="flex justify-center items-center">
                    <button
                      type="submit"
                      className="font-semibold hover:bg-[#B5ED3F]  text-[#fff] bg-[#8DBA2F] lg:text-lg px-6 lg:px-8 pt-2 pb-2 rounded-full  py-3 "
                    >
                      Book Free Consultations
                    </button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;

