import React, { useEffect, useRef } from 'react';
import './popup.css'; // Ensure this file contains the modal styles
import DesktoBg from '../../Assets/Lp/BG-DESKTOP.webp'
import TextImg from '../../Assets/Lp/file.webp'
import { Constant } from '../../Constants/Constant';
const Modal = ({ isOpen, onClose }) => {
    const modalRef = useRef();



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);
    if (!isOpen) return null;
    const onFinish = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const values = Object.fromEntries(formData.entries());

        try {
            let submitDetail = { ...values };
            submitDetail.fullPageUrl = window.location.href;
            submitDetail.companyName = Constant.CompanyName;

            const response = await fetch(
                "https://americanseohub.com/api/v1/registerUserSearchBloom",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(submitDetail),
                }
            );

            if (response.ok) {
                const responseData = await response.json();
                window.location.href=(`${Constant.CompanyUrlWithHHTPS}/thankyou`);
                console.log("API response:", responseData);
            } else {
                console.error("API error:", response.statusText);
            }
        } catch (error) {
            console.error("An error occurred while making the API call:", error);
        }
    };
    return (

        <div className="fixed inset-0 flex justify-center items-center bg-[#000] bg-opacity-50  !z-[999]" >
            <div className="relative max-w-4xl w-full bg-white rounded-2xl shadow-lg overflow-hidden " ref={modalRef}>
                <div className="p-8 !pb-0 flex flex-col items-center md:!items-end  md:flex-row popUpForm">
                    <div className="hidden md:flex flex-col items-center ">
                        <img className=" hidden md:flex z-20 relative   discountImg" alt="popup-image" src={TextImg} />
                        {/* <img className="  flex md:hidden discountMobileImg " alt="popup-image" src={MobileText} /> */}
                    </div>

                    <form onSubmit={onFinish} className="space-y-2 md:space-y-6 p-6 min-w-[300px] mb-5 md:min-w-[400px] rounded-md !z-50 bg-[#000] bg-opacity-30" >
                        <button
                            type="button"
                            onClick={onClose}
                            className="!z-[999] absolute top-2 right-2 text-[#fff] hover:text-gray-900 rounded-full bg-[#000]/30 p-2"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 32 32"
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M4.6 27.4c.8.8 2 .8 2.8 0l8.6-8.6 8.6 8.6c.8.8 2 .8 2.8 0s.8-2 0-2.8L18.8 16l8.6-8.6c.8-.8.8-2 0-2.8s-2-.8-2.8 0L16 13.2 7.4 4.6c-.8-.8-2-.8-2.8 0s-.8 2 0 2.8l8.6 8.6-8.6 8.6c-.8.8-.8 2 0 2.8z" />
                            </svg>
                        </button>
                        <h2 className="subHeading !mt-0 font-extrabold text-[#fff] !mb-2 md:mb-4">Contact Us</h2>
                        <div className="flex flex-col space-y-4">
                            <div className='form-line'>
                                <input
                                    name="name"
                                    type="text"
                                    className="form-input"
                                    required
                                />
                                <label className="!text-[#fff]">Name</label>
                            </div >
                            <div className='form-line'>
                                <input
                                    name="email"
                                    type="text"
                                    className="form-input"
                                    required
                                />
                                <label className="!text-[#fff]">Your email *</label>
                            </div>
                            <div className='form-line'>
                                <input
                                    name="phoneNumber"
                                    type="number"
                                    className="form-input"
                                    maxLength="13"
                                    minLength="9"
                                    required
                                />
                                <label className="!text-[#fff]">Phone</label>
                            </div>
                            <div className='form-line'>
                                <input
                                    name="website"
                                    type="text"
                                    className="form-input"
                                    required
                                />
                                <label className="!text-[#fff]">Website</label>
                            </div>
                        </div>
                        <input
                            type="submit"
                            className="cursor-pointer w-full secondaryText pb-2 px-4 bg-primary !text-[#fff] font-extraBold rounded-md hover:bg-[#8fbc30]"
                            value="Book Free Consultations"
                        />
                    </form>
                    <div className="md:hidden flex justify-center items-end w-full mt-4 relative">
                        <img
                            className="flex md:hidden discountMobileImg"
                            alt="popup-image"
                            src={TextImg}
                        />
                    </div>
                    {/* <div className='relative'>

                        <img className=" !absolute bottom-0  flex md:hidden discountMobileImg " alt="popup-image" src={MobileText} />
                    </div> */}

                </div>


                <div className="absolute inset-0 !z-0">
                    <img
                        alt="popup-image"
                        src={DesktoBg}
                        className="object-cover w-full h-full bg-primary"
                    />
                </div>
            </div>
        </div>
    );
};

export default Modal;
