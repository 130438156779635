import React from "react";
import RightImg from '../../Assets/RightImg.webp'

function SecondSection() {

    return (
        <>
            <div className="w-full flex justify-center items-center relative  ">
                <div className="panel flex w-full pt-0 md:pt-10 gap-20 sm:flex-col fl:flex-col md:flex-row">
                    <div
                        // data-aos="fade-down"
                        className="xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] fl:w-[100%] flex justify-center  flex-col   text-start gap-5"
                    >
                        <h2
                            className={` secondHeading capitalize  text-[#2F2F2F] w-full  gap-5 `}
                        >

                            Confused by Google Algorithm Changes?  <span className="text-primary span-underline"> Let’s Adapt Your SEO Strategy! </span>
                        </h2>

                        <p className={`secondaryText lg:pr-24 `}>
                            This is like placing your bet on blackjack, where changes are constant as a result of the ever-changing algorithms. Different search engines have their algorithms, which change frequently, meaning that what you were doing last month may not work today. If you are wondering why your ranking has gone down or why your traffic is not as high as it used to be, then it is time to make a change. Because you don’t want confusion on the subject to slow you down, you can rely on our team to help you decipher everything anew and adapt your SEO marketing strategy to the new changes. Contact us now so your website remains visible and your business delivers the necessary outcomes  </p>


                        {/* <p className={`secondaryText font-bold !text-[#000]`}>
                            But wait! The question is, ‘Are you all set to get a GMB service?” Let’s see!     </p>


                        <ul className="secondaryText list-disc !text-[#000] space-y-2 font-bold custom-disc">
                            <li className="flex items-end gap-2 border-b-[0.5px] border-[#000] pb-2 pl-5">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11.8994" cy="11.8994" r="11.1782" transform="matrix(1 0 0 -1 0.916992 23.9019)" stroke="#FC573B" stroke-width="1.44235" />
                                    <path d="M10.7972 16.7081L6.68652 12.5974L7.7142 11.5698L10.7972 14.6528L17.414 8.03601L18.4417 9.06369L10.7972 16.7081Z" fill="#FC573B" />
                                </svg>You Own or Manage a Business?</li>
                            <li className="flex items-end gap-2 border-b-[0.5px] border-[#000] pb-2 pl-5">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11.8994" cy="11.8994" r="11.1782" transform="matrix(1 0 0 -1 0.916992 23.9019)" stroke="#FC573B" stroke-width="1.44235" />
                                    <path d="M10.7972 16.7081L6.68652 12.5974L7.7142 11.5698L10.7972 14.6528L17.414 8.03601L18.4417 9.06369L10.7972 16.7081Z" fill="#FC573B" />
                                </svg>You Have a Business Location?</li>
                            <li className="flex items-end gap-2 border-b-[0.5px] border-[#000] pb-2 pl-5">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11.8994" cy="11.8994" r="11.1782" transform="matrix(1 0 0 -1 0.916992 23.9019)" stroke="#FC573B" stroke-width="1.44235" />
                                    <path d="M10.7972 16.7081L6.68652 12.5974L7.7142 11.5698L10.7972 14.6528L17.414 8.03601L18.4417 9.06369L10.7972 16.7081Z" fill="#FC573B" />
                                </svg>You Want to Improve Local Visibility?</li>
                            <li className="flex items-end gap-2 border-b-[0.5px] border-[#000] pb-2 pl-5">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11.8994" cy="11.8994" r="11.1782" transform="matrix(1 0 0 -1 0.916992 23.9019)" stroke="#FC573B" stroke-width="1.44235" />
                                    <path d="M10.7972 16.7081L6.68652 12.5974L7.7142 11.5698L10.7972 14.6528L17.414 8.03601L18.4417 9.06369L10.7972 16.7081Z" fill="#FC573B" />
                                </svg>You Have Accurate Business Information?</li>
                        </ul>


                        <p className={`secondaryText font-bold mt-5`}>
                            If ‘Yes’ is the answer and you want to get more local exposure for your business? Then, your business is just a step closer to making a big difference and the right noise. Let us help you get started and see your local visibility grow.   </p> */}




                    </div>
                    <div className=" xl:w-[45%] lg:w-[40%] md:w-[50%] sm:w-[100%] fl:w-[100%] justify-center items-center flex  ">
                        <img
                            className="  max-w-[20rem] lg:max-w-[30rem] xl:max-w-[40rem] "
                            src={RightImg}
                            alt="how-its-started"
                        />
                    </div>
                </div>

            </div>
        </>
    );
}

export default SecondSection;
