import React from "react";
import { fontSizes } from "../../Constants/Constant";
import GoogleSectionImg from '../../Assets/GoogleSection.webp'
const GoogleSection = () => {
    return (
        <section className="panel">
            <div className="mx-auto flex flex-col lg:flex-row items-center justify-between gap-5  text-start">
                {/* Left Content */}
                <div className="lg:w-1/2 space-y-4">
                    <h2 className="primaryHeading2">
                       Page Two of Google is the <span className="text-lime-500">Digital Black Hole!</span>
                    </h2>

                    <div className=" md:grid grid-cols-2 fl:flex fl:flex-col sm:flex-row fl:items-center  md:justify-start gap-5 !mt-16">
                        {/* Question 1 */}
                        <div class=" bg-[#EDEEEB] fl:w-full fl:h-full hover:bg-gradient-to-b from-[#A1D335] to-[#7AA129] rounded-xl  pt-20 p-6 relative max-w-xs  hover:shadow-custom-hover "
                            // style={{
                            //     boxShadow: '10px 11px 13.4px 0px rgba(0, 0, 0, 0.18)',
                            // }}
                        >
                            <div class="absolute -top-8 left-1/2 transform -translate-x-1/2">
                                <div class="bg-[#EDEEEB] rounded-full border-[#DBD5D5] hover:border-[#82AB2B] border-2 w-28 h-28 flex items-center justify-center shadow-lg">
                                    <span class="!text-[#82AB2B] text-[70px] font-bold">?</span>
                                </div>
                            </div>


                            <div class="text-center my-10">
                                <p class="baseText !text-[#000] font-normal">
                                    Is Your Website Fading Away <br />
                                    On Google’s Second Page?
                                </p>
                            </div>
                        </div>
                        <div class=" bg-[#EDEEEB] fl:w-full fl:h-full hover:bg-gradient-to-b from-[#A1D335] to-[#7AA129] rounded-xl   mt-10 sm:mt-0 pt-20 p-6 relative max-w-xs  hover:shadow-custom-hover">
                            <div class="absolute -top-8 left-1/2 transform -translate-x-1/2">
                                <div class="bg-[#EDEEEB] rounded-full hover:border-[#82AB2B] border-[#DBD5D5] border-2 w-28 h-28 flex items-center justify-center shadow-lg">
                                    <span class="!text-[#82AB2B] text-[70px] font-bold">?</span>
                                </div>
                            </div>


                            <div class="text-center my-10">
                                <p class="baseText !text-[#000] font-normal">
                                Are you letting your website
                                slip through the cracks of SERPs?
                                </p>
                            </div>
                        </div>



                    </div>

                    <p className={`${fontSizes.text_3_xl} font-bold mt-6 `}>
                        Then, you are missing out on Valuable Visitors
                    </p>

                    <p className="baseText lg:pr-5">
                    At Search Bloom, we have the most attractive Search Engine Optimization (SEO) services that any business should go for. Search Engine Optimization (SEO) is our expertise, and our SEO specialist can enhance your site and make it rank well among Search Engine Result Pages (SERPs). Our tested and effective methods and our SEO agency will make your business expand and bring it to Google’s page 1. 
                    </p>

                    <p className={`${fontSizes.text_2_xl} text-[#7AA129] font-bold mt-6 lg:pr-5 `}>
                        NOW is the PERFECT TIME to GROW your Business with our SEO services
                    </p>
                </div>

                {/* Right Image */}
                <div className="mt-8 lg:mt-0 lg:w-1/2 flex justify-center items-center">
                    <img src={GoogleSectionImg} alt="Digital Black Hole" className="w-full object-contain" />
                </div>
            </div>
        </section>
    );
};

export default GoogleSection;
