import React , {useState} from "react";
import Bg from '../../Assets/secCtabg.webp'
import SecLeft from '../../Assets/secLeft.webp'
import SecRight from '../../Assets/secRight.webp'
import { fontSizes } from "../../Constants/Constant";
import ButtonComponent from "../ButtonComponents/ButtonComponent";
import Modal from "../Modal/Modal";
const CtaOne = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <div className="  bg-ctaBg relative md:mt-24"
            style={{
                backgroundImage: `url(${Bg})`,
                backgroundSize: 'cover', // This ensures the image covers the whole div
                backgroundPosition: 'center', // This centers the image
            }}
        >
            {/* Background gradient on top */}
            {/* <div className="absolute inset-0 bg-gradient-to-t from-lime-300 via-white/0 to-lime-300 h-2 rounded-t"></div> */}
            <div className="panel">

                <div className="  mx-auto flex flex-col md:flex-row items-center justify-between  md:py-12 lg:py-16 xl:py-12    z-10">
                    {/* Left side image */}
                    <div className="  hidden sm:block md:w-1/5 ">
                        <img src={SecLeft} alt="Brand left" className=" max-w-[14rem] sm:max-w-[12rem] md:max-w-[10rem] lg:max-w-full absolute bottom-0 mx-auto  left-10" />
                    </div>

                    {/* Middle content */}
                    <div className="text-center md:w-full text-white space-y-4 mx-auto">
                        <h1 className="cta7xl">
                            SICK OF BEING INVISIBLE <br /> ON SERPs?
                        </h1>
                        <p className={`${fontSizes.text_2_xl}`}>
                            Let’s Boost You To The Top Search Results. <br />
                            <span className="text-lime-300">Contact Us Now!</span>
                        </p>
                        <div className="flex justify-center space-x-4">
                        <ButtonComponent onOpen={openModal} />
                        </div>
                    </div>

                    {/* Right side image */}
                    <div className="  md:block md:w-1/5 pt-96 sm:pt-80 md:pt-0 flex justify-center">
                        <img src={SecRight} alt="Brand right" className=" max-w-[14rem] sm:max-w-[12rem] md:max-w-[10rem] lg:max-w-full absolute bottom-0 right-10  mx-auto" />
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} />

            {/* Container for content */}
        </div>
    );
};

export default CtaOne;
