import React, {useState} from "react";
import Bg from '../../Assets/secCtabg.webp'
import CtaThreeImg from '../../Assets/CtaTwoImg.webp'
import CtaThreeRight from '../../Assets/RightVector.webp'
import ButtonComponent from "../ButtonComponents/ButtonComponent";
import Modal from "../Modal/Modal";
const CtaThree = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <div className="relative mt-5 bg-[#EEEEEE] overflow-hidden"
            style={{
                backgroundImage: `url(${Bg})`,
                backgroundSize: 'cover', // This ensures the image covers the whole div
                backgroundPosition: 'center', // This centers the image
            }}>

            <div className=" panel lg:!py-2   overflow-hidden "

            >
                <div className="hidden md:block md:[5%] ">
                    <img src={CtaThreeRight} alt="Brand left" className="absolute top-0 w-[17%] lg:w-[14%]  left-0" />
                </div>
                <div className=" mx-auto flex flex-col md:flex-row items-center justify-between py-0 px-0 md:px-8  z-10">

                    <div className="text-center w-full     text-white space-y-4">
                        <h1 className="cta7xl !text-[#2F2F2F]">
                            Blow Away Your <br className="hidden md:flex" />
                            Competitors
                        </h1>
                        <p className="primaryText text-[#2F2F2F]">
                            Rank High in SERPs with Us! <span className="text-[#7AA129]" > Contact us. </span>
                        </p>
                        <div className="flex justify-center space-x-4">
                            <ButtonComponent onOpen={openModal} css={'!text-[#fff] hover:!text-[#000] !bg-[#000]'} />
                        </div>
                    </div>

                    {/* Right side image */}
                    <div className="flex justify-center items-center md:w-[40%] mt-10 md:mt-0">
                        <img src={CtaThreeImg} alt="Brand right" className="mx-auto" />
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} />

        </div>
    );
};

export default CtaThree;
