


import React, { useState, useEffect } from 'react'

import { Navigation, Pagination } from 'swiper/modules';
import "./SeoSolutionSlider.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { fontSizes } from '../../Constants/Constant';
import vector from '../../Assets/Vector.png'
import slider1 from '../../Assets/slider1.webp'
import slider2 from '../../Assets/slider2.png'
import slider3 from '../../Assets/slider3.png'
import slider4 from '../../Assets/slider4.webp'
import slider5 from '../../Assets/slider5.webp'
import slider6 from '../../Assets/slider6.webp'
import slider7 from '../../Assets/slider7.webp'
import slider8 from '../../Assets/slider8.webp'
import slider9 from '../../Assets/slider8.webp'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";

function SeoSolutionSlider() {
    const [active, setActive] = useState(0)
    const [spaceBetween, setSpaceBetween] = useState(30); // Default spaceBetween value

    const slider = [
        {
            id: 1,
            type: "Off-Page",
            title: 'SEO Optimization',
            sliderImg: slider1,
            text: ` This aims at restoring the highly ranked position of your site through activities which are done off your website.
                                    These techniques help to gain more traffic and establish the reputation of your site.
                                    We can take care of off-page techniques to ensure that your site is visible.`,
            ulli: <>  <li>
                <span class="font-bold">Backlink Building:</span>
                Produce cables anchored from other reliable sites to enhance the esteem of your page.
            </li>
                <li>
                    <span class="font-bold">Social Media Engagement:</span>
                    Marketing, make content shareable and share it across social media.
                </li>
                <li>
                    <span class="font-bold">Guest Posting:</span>
                    Post articles on other sites with backlinks to your website.
                </li>
                <li>
                    <span class="font-bold">Online Reviews:</span>
                    This means you are supposed to deal with positive impacts on your site and at the same time encourage the same.
                </li>
                <li>
                    <span class="font-bold">Influencer Outreach:</span>
                    You have to establish relationships with these influencers for them to help you spread your name.
                </li></>
        },
        {
            id: 2,
            type: "In-Depth ",
            title: 'Keyword Research',
            sliderImg: slider2,
            text: 'If you want to be noticed and increase your company’s size, then you should invest in them. This is the reason we will provide professional keyword research to ensure that you use the correct words to attract the maximum number of visitors and remain ahead of the competitors. Online, your website could not be one of the best for users to decide to visit.',
            ulli: <>  <li>
                <span class="font-bold">Keyword Analysis:</span>
                We try to find out the most relevant keywords that people type in while looking for a business similar to yours. This makes your site appear on the list when the customer is in search of your products or services.
            </li>
                <li>
                    <span class="font-bold">Competitor Research:</span>
                    We verify the terms your competitors are using and their ranking on the search engines. This provides us with inspiration on how you can put on your best and achieve more as a company.
                </li>
                <li>
                    <span class="font-bold">Long-Tail Keywords:</span>
                    We have to focus on particular, less popular keywords, which can be ranked and can bring visitors who will potentially become customers.
                </li>
            </>
        },
        {
            id: 3,
            type: "On-Page",
            title: 'SEO Optimization',
            sliderImg: slider3,
            text: 'We ensure that your page titles and descriptions are clear and also make them interesting. We have the possibility to create basic tags that help you classify your content and set up clear URLs for your readers. We also enhance your images for the efficiency of your site to load faster. Allow us to assist your site to be promoted on the web to gain more traffic.',
            ulli: <>  <li>
                <span class="font-bold">Title Tags and Meta Descriptions:</span>
                Appealing titles and subtitles
            </li>
                <li>
                    <span class="font-bold">Header Tags:</span>
                    As for the heading structure, one must use H1, H2, and H3 tags for the content’s comprehensive division.                </li>
                <li>
                    <span class="font-bold">URL Structure:</span>
                    Uncomplicated and plain web addresses.                </li>
                <li>
                    <span class="font-bold">Content Optimization:</span>
                    Developing keywords-rich and valuable content.                </li>
                <li>
                    <span class="font-bold">Image Optimization</span>
                    : Fresh images make your web pages load faster as well as enhance the search engine's ranking.                </li></>
        },
        {
            id: 4,
            type: "Technical",
            title: 'SEO',
            sliderImg: slider4,
            text: 'We ensure your website remains functional and on top of the search engine results page. We give attention to the page loading rates, its compatibility with mobile devices and other technical aspects. Let us take care of the technology aspect with the aim of increasing the speed and relevancy of its ranking.',
            ulli: <>  <li>
                <span class="font-bold">Title Tags and Meta Descriptions:</span>
                Appealing titles and subtitles
            </li>
                <li>
                    <span class="font-bold">Site Speed:</span>
                    This would make the loading time faster to increase the experience of the users.              </li>
                <li>
                    <span class="font-bold">Mobile-Friendliness:</span>
                    Make sure that your site appears well on all or any device of your choice.                </li>
                <li>
                    <span class="font-bold">XML Sitemaps:</span>
                    Assist Insite map in explaining the structural composition to search engine.         </li>
                <li>
                    <span class="font-bold">Robots. Txt:</span>
                    Decide on which part of the site the search engine spiders can crawl.       </li>
                <li>
                    <span class="font-bold">HTTPS:</span>
                    Make your site secure to enhance your visitors’ safety when using the site.       </li>
            </>
        },
        {
            id: 5,
            type: "SEO Content ",
            title: 'Creation and Marketing',
            sliderImg: slider5,
            text: 'To achieve these goals, we create high-quality content for visitors to engage themselves with. That means we can handle blog posts or even simple statuses on social media to increase traffic and recognition of your site. Thus, let us get down to writing and sharing content in order to boost your business.',
            ulli: <>  <li>
                <span class="font-bold">Title Tags and Meta Descriptions:</span>
                Blog Posts: It is important that you come up with interesting articles to write on your site.
            </li>
                <li>
                    <span class="font-bold">Social Media:</span>
                    People use shares to update and connect to other individuals.           </li>
                <li>
                    <span class="font-bold">Website Content:</span>
                    It is, therefore, important to create well-presented and interesting page content.                </li>
                <li>
                    <span class="font-bold">Email Marketing:</span>
                    The fourth way to influence your audience is through email, make appropriate emails.       </li>
                <li>
                    <span class="font-bold">Content Promotion:</span>
                    The steps used to ensure that the content you have created can be viewed by as many people as possible, and hopefully circulated.     </li>
            </>
        },
        {
            id: 6,
            type: "Link Building ",
            title: '',
            sliderImg: slider6,
            text: 'We assist in drawing attention towards your site by generating good links from other sites. This improves your ranking on the search engine, hence attracting more visitors to your site. Let us make good link profiles to enhance your web visibility.',
            ulli: <>  <li>
                <span class="font-bold">Quality Links:</span>
                Link from other reliable websites.
            </li>
                <li>
                    <span class="font-bold"> Outreach:</span>
                    To create networks, you should call other sites.          </li>
                <li>
                    <span class="font-bold"> Guest Posts:</span>
                    Create articles on other sites but with hyperlinks that lead to your site.               </li>
                <li>
                    <span class="font-bold">Link Analysis:</span>
                    Ensure that your links are properly working so that they do not bring a bad name to your website.      </li>
                <li>
                    <span class="font-bold">Link Strategy:</span>
                    Coordinate link-building campaigns to achieve greater outcomes.    </li>
            </>
        },
        {
            id: 6,
            type: "SEO Analytics  ",
            title: 'And Reporting',
            sliderImg: slider7,
            text: 'We monitor how your site performs with SEO and provide you with detailed, simplified reports. We scrutinize your visitors, ranking and many other important factors. Let us demonstrate to you how your website is working and how you can increase the value of your site.',
            ulli: <>  <li>
                <span class="font-bold">Traffic Reports:</span>
                Get to know the number of persons who visit your site.
            </li>
                <li>
                    <span class="font-bold"> Ranking Data:</span>
                    Discover, who placed your site and what the rank of your site is.         </li>
                <li>
                    <span class="font-bold"> Performance Check:</span>
                    Understand how effectively you’re making use of the SEO technique on your site.            </li>
                <li>
                    <span class="font-bold">Progress Updates:</span>
                    Stay informed about your improvements and outcomes with SEO.    </li>
                <li>
                    <span class="font-bold">Data Insights:</span>
                    Helps in making your site better.    </li>
            </>
        },
        {
            id: 7,
            type: "SEO Audit",
            title: 'Service',
            sliderImg: slider8,
            text: 'In order to know how good your website is doing in the search engines, we run a check on your website. The audit shows you where there are problems and where there is room for improvement so that you can rectify issues and optimise your website. Allow us to give you a comprehensive analysis of the site to increase your site’s search engine ranking.',
            ulli: <>  <li>
                <span class="font-bold">Site Analysis:</span>
                Check your site’s general well-being and productivity.
            </li>
                <li>
                    <span class="font-bold"> Technical Issues:</span>
                    Looking for difficulties that could contribute to your site’s decline.        </li>
                <li>
                    <span class="font-bold"> Content Evaluation:</span>
                    If possible, make sure that every content created is Search Engine friendly.           </li>
                <li>
                    <span class="font-bold">Progress Updates:</span>
                    Stay informed about your improvements and outcomes with SEO.    </li>
                <li>
                    <span class="font-bold">Data Insights:</span>
                    Helps in making your site better.    </li>
            </>
        },
        {
            id: 8,
            type: "Voice Search ",
            title: 'Optimization',
            sliderImg: slider9,
            text: 'We ensure that your site is optimized with voice searches. This means when individuals conduct voice searches, they are likely to stumble on your site. To be precise, let us assist in collecting traffic from voice searches and increasing your site’s awareness.',
            ulli: <>  <li>
                <span class="font-bold">Natural Language:</span>
                They are advised to use plain and everyday language, and all the speakers are encouraged to talk naturally and in day-to-day language.
            </li>
                <li>
                    <span class="font-bold"> Featured Snippets:</span>
                    Make your content appear under the quick answers section.       </li>
                <li>
                    <span class="font-bold"> Local Search:</span>
                    Pay attention to local questions and queries only.           </li>
                <li>
                    <span class="font-bold">Structured Data:</span>
                    Place a mark around your content to assist the spiders in identifying the relevant content.   </li>
                <li>
                    <span class="font-bold">Mobile Optimization:</span>
                    Ensure that your site is endowed with a responsive web design in order to enhance usability for those using mobile phones to access your site.    </li>
            </>
        },
    ]
    const updateSpaceBetween = () => {
        const viewportWidth = window.innerWidth;

        // Define breakpoints for changing spaceBetween values
        if (viewportWidth < 768) {
            setSpaceBetween(120);
        } else {
            setSpaceBetween(30);
        }
    };
    useEffect(() => {
        updateSpaceBetween(); // Initial call
        window.addEventListener('resize', updateSpaceBetween);
        return () => {
            window.removeEventListener('resize', updateSpaceBetween);
        };
    }, []);
    return (
        <section class="lg:mt-10">
            {/* <div class="container"> */}
            <div style={{ margin: "0px" }} class="row">
                <div style={{ padding: "0px" }} class="col-md-12">
                    <div className='panel mx-auto flex flex-col md:flex-row justify-center items-center text-start !py-10 md:!py-16 gap-5'>

                        <h2 className={` flex-1 ${fontSizes.text_5P5_xl} font-bold  !text-[#2F2F2F] leading-[28px] md:leading-[40px] xl:leading-[55px]  `}>All-Inclusive SEO Solutions that Bloom your Business Website!</h2>
                        <p className=" flex-1 SecondaryText text-[#000] mb-8">
                        These all-encompassing SEO services are aimed at assisting your website grow. We provide you with keyword research, on-page optimization, link building, and any other bit in between and after. To ensure that you are able to concentrate more on your business, our SEO expert is here to help with your SEO concerns. Here is the service we provide:
                        </p>
                    </div>
                    {/* {slider.map((slide, index) => (
                        <div key={index} class="max-w-5xl  rounded-lg  mx-auto overflow-hidden text-start">
                            <div class="relative flex justify-between items-end pt-20 border-[20px] border-[#7AA129] rounded-[50px]  !min-h-[550px]  "
                                style={{
                                    backgroundImage: `
                                linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), 
                                url(${slide.sliderImg})
                              `,
                                    backgroundSize: 'cover', // This ensures the image covers the whole div
                                    backgroundPosition: 'center', // This centers the image
                                }}
                            >
                                <div class="p-5 text-white w-1/2 ">
                                    <h2 className={`${fontSizes.text_3_xl} uppercase`} style={{
                                        letterSpacing: "9.509px"
                                    }} > {slide.type}  </h2>
                                    <h2 className={`${fontSizes.text_3_xl} font-extrabold uppercase`} > {slide.title} </h2>
                                    <p class="text-sm mb-6">
                                        {slide.text}
                                    </p>
                                </div>

                                <div class="p-5 bg-black !min-w-[0px] !min-h-[0px] grid grid-cols-12 h-full gap-2  !text-[#fff]  w-1/2">
                                    <img className='h-full col-span-1 ' src={vector} alt="" />
                                    <ul class={`space-y-4 text-sm col-span-11 `}>
                                        {slide.ulli}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))} */}
                    <Swiper
                        slidesPerView={'auto'}
                        centeredSlides={true}
                        spaceBetween={spaceBetween}
                        onSlideChange={e => setActive(e.activeIndex)}
                        pagination={{
                            type: 'custom'
                        }}
                        navigation={false}
                        modules={[Pagination, Navigation]}
                        className="mySwiper2"
                    >
                        {slider.map((slide, index) => (
                            <SwiperSlide>
                                <div key={index} className='md:max-w-5xl fl:!w-[95vw]' style={{ transform: `scale(${active === index ? "1" : "0.7"})`, transition: "all 0.5s" }}>
                                    <div className="  rounded-lg  mx-auto overflow-hidden text-start">
                                        <div className="relative flex flex-col md:flex-row  justify-center  md:justify-between items-center md:items-start  md:pt-20 border-[10px] md:border-[20px] border-[#7AA129] rounded-[50px]  !min-h-[550px]  "
                                            style={{
                                                background: `
                                                linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)), 
                                                url(${slide.sliderImg})
                                              `,
                                                // backgroundColor: `linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0, 0.9)) `,
                                                // backgroundImage: `url(${slide.sliderImg}) `,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                            }}
                                        >
                                            <div className="p-5 text-white w-full md:w-1/2 ">
                                                <h2 className={`${fontSizes.text_3_xl} uppercase`} style={{
                                                    letterSpacing: "9.509px"
                                                }} > {slide.type}  </h2>
                                                <h2 className={`${fontSizes.text_3_xl} font-extrabold uppercase`} > {slide.title} </h2>
                                                <p className="text-sm  ">
                                                    {slide.text}
                                                </p>
                                            </div>

                                            <div className="p-5 bg-black !min-w-[0px] !min-h-[0px] grid grid-cols-12 h-full gap-2  !text-[#fff] w-full md:w-1/2">
                                                <img className=' object-contain h-full col-span-1 ' src={vector} alt="" />
                                                <ul className={`space-y-4 text-sm col-span-11 `}>
                                                    {slide.ulli}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                        ))
                        }




                    </Swiper>
                </div>
                {/* </div> */}
            </div>
        </section>
    )
}

export default SeoSolutionSlider

