import React, {useState} from "react";
import Bg from '../../Assets/secCtabg.webp'
import CtaTwoImg from '../../Assets/CtaTwoImg.webp'
import ButtonComponent from "../ButtonComponents/ButtonComponent";
import { fontSizes } from "../../Constants/Constant";
import Modal from "../Modal/Modal";
const CtaTwo = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <div className="  lg:!py-2 bg-[#7aa129] relative mt-5 "
            style={{
                backgroundImage: `url(${Bg})`,
                backgroundSize: 'cover', // This ensures the image covers the whole div
                backgroundPosition: 'center', // This centers the image
            }}
        >
            <div className=" panel mx-auto  flex flex-col gap-10 md:flex-row items-center justify-between py-0 px-0 md:px-8  z-10">

                <div className="text-center w-full     text-white space-y-4">
                    <h1 className="cta9xl">
                        Take the Reins
                    </h1>
                    <p className={`${fontSizes.text_2_xl}`}>
                        Claim Your Free SEO Audit Today
                    </p>
                    <div className="flex justify-center space-x-4">
                     <ButtonComponent onOpen={openModal} css2={'!text-[#fff] !bg-[#A1D335]'} />
                    </div>
                </div>

                {/* Right side image */}
                <div className="flex justify-center items-center md:w-[40%] ">
                    <img src={CtaTwoImg} alt="Brand right" className="mx-auto" />
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} />
        </div>
    );
};

export default CtaTwo;
