



import React from "react";
import { Collapse, theme, ConfigProvider } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { fontSizes } from "../../Constants/Constant";


const getItems = (panelStyle) => [
  {
    key: "1",
    label: "Better SEO Strategies",
    children: (
      <p>{`Basic and efficient approaches.`}</p>
    ),
    style: panelStyle,
  },
  {
    key: "2",
    label: "Increased Website Traffic ",
    children: (
      <p>{`Greater traffic to your website.`}</p>
    ),
    style: panelStyle,
  },
  {
    key: "3",
    label: "Improved Search Rankings ",
    children: (
      <p>{`Better rankings with search engines.`}</p>
    ),
    style: panelStyle,
  },
  {
    key: "4",
    label: "Clear, Easy Reports",
    children: (
      <p>{`Make an understanding of the results of your SEO strategies.`}</p>
    ),
    style: panelStyle,
  },
  {
    key: "5",
    label: "Expert Team Support",
    children: (
      <p>{`So, that must be why you’re here!`}</p>
    ),
    style: panelStyle,
  },
  // {
  //   key: "5",
  //   label: "Real-Time Customer Engagement: ",
  //   children: (
  //     <p>{`Want to connect with your customers right away? we set up live chat on your GMB profile so you can answer questions and keep customers happy. Stay in touch and make sure your customers are always smiling!`}</p>
  //   ),
  //   style: panelStyle,
  // },
];
function SecondFaq({ items }) {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: "#F8F8F8",
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  return (
    <div className="w-full flex justify-center items-center ">
      <div className="max-w-[1320px] w-full  flex  flex-col gap-4">
      
        <div  className="w-full secondFaq">
          <ConfigProvider
            theme={{
              components: {
                Collapse: {
                  contentBg: "#fff",
                  headerBg: "#fff",
                },
              },
              token: {
                colorTextHeading: "#000000",
                colorText: "#000000",
              },
            }}
          >
            <Collapse
              className={`w-full ${fontSizes.base} !text-[#000000] !bg-transparent`}
              bordered={false}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined className="!text-[#000000] !text-lg" rotate={isActive ? 90 : 0} />
              )}
              
              items={items ? items : getItems(panelStyle)}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
}

export default SecondFaq;
