"use client";
import React from "react";
import { Collapse, theme, ConfigProvider } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { fontSizes } from "../../Constants/Constant";

const text = `
A dog is a type of domesticated animal.
Known for its loyalty and faithfulness,
it can be found as a welcome guest in many households across the world.
`;
const getItems = (panelStyle) => [
  {
    key: "1",
    label: "What is SEO, and why do I need it?",
    children: (
      <p>{`SEO stands for Search Engine Optimization. It helps your website rank higher in search results so more people can find you. With good SEO, your site gets more visitors and grows your business.`}</p>
    ),
    style: panelStyle,
  },
  {
    key: "2",
    label: "How does Search Bloom improve my website’s SEO?",
    children: (
      <p>{`Search Bloom uses proven methods to boost your site’s rankings. We do things like keyword research, content updates, and link building to help your site get noticed.`}</p>
    ),
    style: panelStyle,
  },
  {
    key: "3",
    label: "What could be the benefits and costs of SEO services for small business?",
    children: (
      <p>{`Investing in SEO services for small business can majorly increase your brand exposure and bring more traffic. As for SEO costs for small business they can vary based on several factors. Reach out to us so we can discuss your requirements in detail. `}</p>
    ),
    style: panelStyle,
  },
  {
    key: "4",
    label: "How long will it take to see SEO ads?",
    children: (
      <p>{`SEO advertising results take time. Typically, you can start to see improvements in a few months. Search Bloom works hard to get the best results as quickly as possible.`}</p>
    ),
    style: panelStyle,
  },
  {
    key: "5",
    label: "What makes Search Bloom different from other SEO service?",
    children: (
      <p>{`Search Bloom stands out because we offer custom strategies tailored just for your site. We focus on your unique needs and keep you updated with clear reports.`}</p>
    ),
    style: panelStyle,
  },
  {
    key: "6",
    label: "How can I track my SEO progress with Search Bloom?",
    children: (
      <p>{`Search Bloom provides regular, easy-to-understand reports on your site’s performance. You’ll see how your rankings and traffic improve over time.`}</p>
    ),
    style: panelStyle,
  },
];
function FaqSection({ items }) {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: "#F8F8F8",
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  return (
    <div className="w-full flex justify-center items-center fl:px-5 md:px-10 lg:px-20">
      <div className="panel w-full  flex  flex-col gap-8 mainFaq">
        <div
          
          className="text-center flex flex-col gap-3 mb-5"
        >
          <h1 className={`${fontSizes.primaryHeading} font-semibold`}>
          What You Need to Know
          </h1>
        </div>
        <div  className="w-full text-start">
          <ConfigProvider
            theme={{
              components: {
                Collapse: {
                  contentBg: "#F8F8F8",
                  headerBg: "#F8F8F8",
                },
              },
              token: {
                colorTextHeading: "#0000",
                colorText: "gray",
              },
            }}
          >
            <Collapse
              className={`w-full ${fontSizes.text_2_xl}  `}
              bordered={false}
              defaultActiveKey={["1"]}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined className="!text-[#000] !bg-[#DADADA] md:!text-xl p-2 rounded-full" rotate={isActive ? 90 : 0} />
              )}
              style={{ background: "#fff" }}
              items={items ? items : getItems(panelStyle)}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
}

export default FaqSection;
