import React from "react";
import HoldOnImg from '../../Assets/holdOnImg.webp'
import Bg from '../../Assets/check.webp'
import SecondFaq from "./SecondFaq";
import { fontSizes } from "../../Constants/Constant";
const HoldOnSection = () => {
    return (
        <div
            style={{
                backgroundImage: `url(${Bg})`,
                backgroundSize: 'cover', // This ensures the image covers the whole div
                backgroundPosition: 'center', // This centers the image
            }}>


            <section className="panel"

            >

                <div className="  max-w-5xl grid grid-cols-1  md:grid-cols-2 text-start mb-5 md:mb-10 mx-auto ">
                    <div className="flex items-center md:text-end ">
                        <div className="cta9xl !text-[#000] border-dotted border-2 border-[#A1D335] p-2 mx-auto" >
                            hold on
                        </div>

                    </div>
                    <div className="border-s-4 border-[#A1D335]  px-2 md:p-2 mt-5 md:mt-0 ">
                        <div className={`${fontSizes.text_5P5_xl} leading-7 md:leading-normal  `} >
                            <span className="font-bold">Let’s See Why</span> SEO <br className="hidden md:block" /> is a Big Deal.
                        </div>
                    </div>
                </div>

                {/* <div className="flex  text-start   mx-auto">
                <div className="cta9xl !text-[#000] border-dotted border-2 border-primary">
                    hold on
                </div>
                <div className={`${fontSizes.text_5P5_xl}  `} >
                    <span className="font-bold">Let’s See Why</span>  SEO <br /> is a Big Deal.
                </div>
            </div> */}


                <div className="mx-auto flex flex-col md:flex-row items-center justify-between   text-start">
                    {/* Left Content */}
                    <div className="md:w-1/2  md:space-y-0 lg:space-y-4">
                        <h2 className="primaryHeading2">
                            Why SEO  <span className="text-[#7AA129]">Pays Off Big Time</span>
                        </h2>

                        <div className="md:max-w-[80%] lg:max-w-[80%] pt-5 md:pt-10 lg:pt-20" >
                            <SecondFaq />
                        </div>

                        {/* <p className={`${fontSizes.text_3_xl} font-bold mt-6 `}>
                        Then, you are missing out on Valuable Visitors
                    </p>

                    <p className="baseText lg:pr-5">
                        At Search Bloom, we have the most effective Search Engine Optimization (SEO) strategies to ensure that your website is highly visible and optimized for page one of search engine results. Don’t let your business fall into the black hole of page two of Google. Let us help you improve your rankings and start converting traffic into leads and better revenue.
                    </p>

                    <p className={`${fontSizes.text_2_xl} text-[#7AA129] font-bold mt-6 lg:pr-5 `}>
                        NOW is the PERFECT TIME to GROW your Business with our SEO services
                    </p> */}
                    </div>

                    {/* Right Image */}
                    <div className="mt-8 lg:mt-0 md:w-1/2 flex flex-col justify-center items-center">
                        <img src={HoldOnImg} alt="Digital Black Hole" className="w-full object-contain" />
                        <p className="baseText lg:pr-5">
                        In simple terms, SEO is all about increasing the RANKING of your WEBSITE in Search Engine Result Pages (SERPs) such as Google/Bing/Yahoo. If you optimize the interface of your site in terms of Search Engine Results, then you are more likely to get increased organic traffic. SEO also refers to the process of making improvements to the site in terms of content, structure, links, to fits, which can be easily understood by the search engines.                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HoldOnSection;
