import React, {useState} from "react";
import Bg from '../../Assets/secCtabg.webp'
import CtaSevenImg from '../../Assets/CtaSevenImg.webp'
import ButtonComponent from "../ButtonComponents/ButtonComponent";
import Modal from "../Modal/Modal";
const CtaSeven = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <div className="  py-5 lg:!py-2 bg-[#7aa129] relative md:mt-24"
            style={{
                backgroundImage: `url(${Bg})`,
                backgroundSize: 'cover', // This ensures the image covers the whole div
                backgroundPosition: 'center', // This centers the image
            }}
        >
            <div className=" panel mx-auto flex flex-col md:flex-row items-center justify-between py-0 xl:py-20 px-4 md:px-8  z-10">

                <div className="text-center md:w-full text-white space-y-4 mx-auto">
                    <p className="secondaryText uppercase" style={{
                        letterSpacing: "6.931px"
                    }} >
                        Want More Traffic?
                    </p>
                    <h1 className="cta7xl">
                        Hit this Button, and <br className="hidden lg:flex" />
                        Let’s Discuss the Rest!
                    </h1>

                    <div className="mx-auto">
                        <ButtonComponent onOpen={openModal} css2={'!text-[#fff] !bg-[#A1D335]'} />
                    </div>
                </div>

                {/* Right side image */}
                <div className="flex justify-center items-center md:w-[40%]  ">
                    <img src={CtaSevenImg} alt="Brand right" className=" relative xl:absolute  max-w-[20rem] xl:max-w-[34rem] "   />
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} />

        </div>
    );
};

export default CtaSeven;
