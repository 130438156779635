import React, { useState, useEffect } from 'react';
import "./App.css";
import Layout from "./Components/Layout";
import HeroSection from "./Components/HeroSection/HeroSection";
import OurServicesSection from "./Components/OurServicesSection/OurServicesSection";
import FaqSection from "./Components/FaqSection/FaqSection";
import SecondSection from "./Components/SecondSection/SecondSection";
import ThirdSection from "./Components/ThirdSection/ThirdSection";
import FifthSection from "./Components/FifthSection/FifthSection";
import SixthSection from "./Components/SixthSection/SixthSection";
import NinthComponents from "./Components/NinthComponents/NinthComponents";
import TweleveSection from "./Components/TweleveSection/TweleveSection";
import ForteenSection from "./Components/ForteenSection/ForteenSection";
import EightSection from "./Components/EightSection/EightSection";
import FourthSection from "./Components/FourthSection/FourthSection";
import FifteenSection from "./Components/FifteenSection/FifteenSection";
import TherteenSection from "./Components/TherteenSection/TherteenSection";
import EleventhSection from "./Components/EleventhSection/EleventhSection";
import Modal from "./Components/Modal/Modal";
import CtaOne from './Components/CtaOne/CtaOne';
import GoogleSection from './Components/GoogleSection/GoogleSection';
import CtaTwo from './Components/CtaTwo/CtaTwo';
import HoldOnSection from './Components/HoldOnSection/HoldOnSection';
import CtaThree from './Components/CtaThree/CtaThree';
import NoMoreSection from './Components/NoMoreSection/NoMoreSection';
import ReviewSection from './Components/ReviewSection/ReviewSection';
import CtaFour from './Components/CtaFour/CtaFour';
import CtaFive from './Components/CtaFive/CtaFive';
import CtaSix from './Components/CtaSix/CtaSix';
import CtaSeven from './Components/CtaSeven/CtaSeven';
import CtaEight from './Components/CtaEight/CtaEight';
import GhostwritingService from './Components/GhostwritingService/GhostwritingService';
import CtaNine from './Components/CtaNine/CtaNine';
import HighBounce from './Components/HighBounce/HighBounce';
import CtaTen from './Components/CtaTen/CtaTen';
import FormulaSection from './Components/FormulaSection/FormulaSection';
import SeoFormSection from './Components/SeoFormSection/SeoFormSection';
import StartegyComponent from './Components/StartegyComponent/StartegyComponent';
import TestimonialSection from './Components/TestimonialSection/TestimonialSection';
import testimonial1 from './Assets/client1.webp'
import testimonial2 from './Assets/client2.webp'
import testimonial3 from './Assets/client3.webp'
import SeoSolutionSlider from './Components/SeoSolutionSlider/SeoSolutionSlider';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';
// import SeoSolutionSlider from './Components/SeoSolutionSlider/SeoSolutionSlider';
function App() {

  // useEffect(() => {
  //   AOS.init({
  //     duration: 1000, // Animation duration in milliseconds
  //     easing: 'ease-in-out', // Animation easing
  //     once: false, // Animation happens only once
  //     mirror: true,
  //   });

  //   const allDivs = document.querySelectorAll('.panel'); 
  //   allDivs.forEach(div => {
  //     div.setAttribute('data-aos', 'fade-up'); 
  //   });
  // }, []);




  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Set a timeout to display the modal after 10 seconds
    const timer = setTimeout(() => {
      setIsModalOpen(true);
    }, 10000); // 10 seconds

    // Cleanup the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };



  return (
    <div className="App">
      <Layout>


        <HeroSection />
        <CtaOne />
        <GoogleSection />
        <CtaTwo />
        <HoldOnSection />
        <CtaThree />
        <StartegyComponent />
        <CtaFour />
        <SecondSection />
        <ReviewSection />
        <CtaFive />
        <NoMoreSection />
        <CtaSix />
        <SeoSolutionSlider />
        {/* <NoMoreSection /> */}
        <CtaSeven />
        <FormulaSection />
        <CtaEight />
        <GhostwritingService />
        <CtaNine />
        <HighBounce />
        <CtaTen />





        <TestimonialSection
          description={
            "SEO services that meet your specific requirements are developed in consultation with our team. In return, you will receive straightforward and truthful information on the progress of things. You can rely on us to help you get more traffic to your website through effective search engine optimization. So, let us ensure your business makes its mark on the web and is performing outstandingly."
          }
          services={[
            {
              image: testimonial1,
              name: 'Jessica Martin',
              description:
                "SEO services that meet your specific requirements are developed in consultation with our team. In return, you will receive straightforward and truthful information on the progress of things. You can rely on us to help you get more traffic to your website through effective search engine optimization. So, let us ensure your business makes its mark on the web and is performing outstandingly.",
            },
            {
              image: testimonial2,
              name: 'Sienna Jules',
              description:
                "I have bunch of favorite results from Search Bloom not depending on the topic I was searching for. I am more aware of my website and people are giving more attention to it and more importantly my business is expanding. I shall agree with them that they are more or less the best in the town with regards to the SEO skills that they possess as well as their ability to be very clear with the reports. I am satisfied with the whitelabel SEO service I got.",
            },
            {
              image: testimonial3,
              name: 'Marian Luke',
              description:
                "My friend recommended to hire SEO agency and referred Search Bloom. I must say It was nice working with them.They helped me improve my SEO and rank my website higher on search results. I hear that frequently and have an indication of what is taking place.",
            },
          ]}
        />

        {/* <HeroSection />
        <ThirdSection />
        <FourthSection />
        <FifthSection />
        <SixthSection />
        <EightSection />
        <NinthComponents />
        <OurServicesSection />
        <EleventhSection />
        <TweleveSection />
        <TherteenSection />
       
        <ForteenSection />
        <FifteenSection /> */}
        <FaqSection />

        <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
        {/* 



       */}





        {/* <IndustriesCarousel /> */}

        {/* 
        <PortfolioSlider />
        <SeoAuditForm />
        <ProblemsSection />
        <ConsultSection />
        <JointSection />
       
        <ContactUsForm /> */}
      </Layout>
    </div>
  );
}

export default App;
