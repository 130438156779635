

import React from "react";
import { fontSizes } from "../../Constants/Constant";
import CenterImg from '../../Assets/trash.webp'
import SecondFaq from "./SecondFaq";
export default function StartegyComponent() {
    return (
        <div className="  flex justify-center items-center ">
            <div className="panel rounded overflow-hidden  mx-auto text-start">
                <div className="flex justify-center items-center flex-col">
                    <h2
                        className={`cta7xl !text-[#2F2F2F] uppercase font-extrabold text-center  md:leading-[50px] max-w-7xl mx-auto`}
                    >
                        Is Your Current <span className="!text-[#7AA129]">  SEO Strategy </span>
                        Falling Short?
                    </h2>
                    <p className={`${fontSizes.text_3_xl}  `}>
                        If Yes, Let’s Do It Correctly.
                    </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 !gap-y-4 md:gap-5 relative pt-5 md:pt-10">
                    <div className=" md:p-5 ">
                        <div className="">
                            <p  className={`${fontSizes.text_2_xl} text-[#000000] py-2   `}
                            >
                                See how we do things better. Our SEO company uses simple, proven and effective methods to get your website noticed and bring in more visitors – and by visitors, we mean valuable visitors. We will assure you of better improvement and growth in your SEO for business. Let’s get your website to where it should be.
                            </p>
                        </div>

                    </div>
                    <div className="px-5  pb-0 flex justify-center items-end">
                        <img className=" max-w-[20rem]  md:max-w-[36rem] " src={CenterImg} alt="Sample" />
                    </div>

                    <div  className=" h-full  flex flex-col justify-between gap-5">
                        <div className="p-3  ">
                            <div
                                className={`text-[#7AA129] capitalize  leading-5 ${fontSizes.text_3_xl}  `}
                            >
                                Here’s What We Offer:
                            </div>
                            <div className="mt-5">
                                <SecondFaq />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
