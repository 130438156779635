


import React, { useState } from "react";
import NoMoreLeft from '../../Assets/NomoreImg.webp'
import ButtonComponent from "../ButtonComponents/ButtonComponent";

function NoMoreSection() {

    return (
        <>
            <div className="w-full flex justify-center items-center relative pt-5 md:pt-10 ">
                <div className="panel flex w-full pt-10 gap-10 md:gap-20 sm:flex-col fl:flex-col-reverse md:flex-row-reverse">
                    <div
                        // data-aos="fade-down"
                        className="xl:w-[45%] lg:w-[50%] md:w-[50%] sm:w-[100%] fl:w-[100%] flex justify-center items-center  flex-col xl:px-0 lg:px-6 md:px-6  text-start gap-5"
                    >
                        <h2
                            className={` secondHeading capitalize  text-[#2F2F2F] w-full  gap-5 `}
                        >

                            No More Outdated Tactics!   <span className="text-primary span-underline"> Refresh Your SEO.</span>
                        </h2>

                        <p className={`secondaryText lg:pr-24 `}>
                            If your SEO technique seems to remain in the Stone Age, then you should consider an overhaul. Such approaches may even bring losses and harm your rankings, leaving you behind the competition. Whether you are failing to stick to the traditional keywords, are not aware of, or are avoiding the new trends, Search Bloom can assist in your case. Here, we will spend considerable time scrutinizing what you are currently doing in terms of strategy and what will not benefit your business anymore. Then we will discard these and reinstate your strategy with new and more effective modern ways. Want to put an end to the series of ‘Follow the Leader’? Give your SEO the ultimate boost and get in touch with Search Bloom! </p>





                        

                        {/* <p className={`secondaryText font-bold !text-[#000]`}>
                            But wait! The question is, ‘Are you all set to get a GMB service?” Let’s see!     </p>


                        <ul className="secondaryText list-disc !text-[#000] space-y-2 font-bold custom-disc">
                            <li className="flex items-end gap-2 border-b-[0.5px] border-[#000] pb-2 pl-5">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11.8994" cy="11.8994" r="11.1782" transform="matrix(1 0 0 -1 0.916992 23.9019)" stroke="#FC573B" stroke-width="1.44235" />
                                    <path d="M10.7972 16.7081L6.68652 12.5974L7.7142 11.5698L10.7972 14.6528L17.414 8.03601L18.4417 9.06369L10.7972 16.7081Z" fill="#FC573B" />
                                </svg>You Own or Manage a Business?</li>
                            <li className="flex items-end gap-2 border-b-[0.5px] border-[#000] pb-2 pl-5">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11.8994" cy="11.8994" r="11.1782" transform="matrix(1 0 0 -1 0.916992 23.9019)" stroke="#FC573B" stroke-width="1.44235" />
                                    <path d="M10.7972 16.7081L6.68652 12.5974L7.7142 11.5698L10.7972 14.6528L17.414 8.03601L18.4417 9.06369L10.7972 16.7081Z" fill="#FC573B" />
                                </svg>You Have a Business Location?</li>
                            <li className="flex items-end gap-2 border-b-[0.5px] border-[#000] pb-2 pl-5">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11.8994" cy="11.8994" r="11.1782" transform="matrix(1 0 0 -1 0.916992 23.9019)" stroke="#FC573B" stroke-width="1.44235" />
                                    <path d="M10.7972 16.7081L6.68652 12.5974L7.7142 11.5698L10.7972 14.6528L17.414 8.03601L18.4417 9.06369L10.7972 16.7081Z" fill="#FC573B" />
                                </svg>You Want to Improve Local Visibility?</li>
                            <li className="flex items-end gap-2 border-b-[0.5px] border-[#000] pb-2 pl-5">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11.8994" cy="11.8994" r="11.1782" transform="matrix(1 0 0 -1 0.916992 23.9019)" stroke="#FC573B" stroke-width="1.44235" />
                                    <path d="M10.7972 16.7081L6.68652 12.5974L7.7142 11.5698L10.7972 14.6528L17.414 8.03601L18.4417 9.06369L10.7972 16.7081Z" fill="#FC573B" />
                                </svg>You Have Accurate Business Information?</li>
                        </ul>


                        <p className={`secondaryText font-bold mt-5`}>
                            If ‘Yes’ is the answer and you want to get more local exposure for your business? Then, your business is just a step closer to making a big difference and the right noise. Let us help you get started and see your local visibility grow.   </p> */}




                    </div>
                    <div className=" xl:w-[55%] lg:w-[50%] md:w-[50%] sm:w-[100%] fl:w-[100%] justify-center items-center flex   ">
                        <img
                            // className="  max-w-[20rem] lg:max-w-[30rem] xl:max-w-[40rem] "
                            src={NoMoreLeft}
                            alt="how-its-started"
                        />
                    </div>
                </div>

            </div>
        </>
    );
}

export default NoMoreSection;
