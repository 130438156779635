import React from "react";

function Footer() {
  return (
    <div className="flex justify-center items-center flex-col xl:pl-5 lg:pl-5 md:pl-5 sm:pl-0 fl:pl-0 pt-5 text-white bg-[#1A2024]">
      {/* <div className="lg:px-10">
        <FooterCTA />
      </div> */}

      {/* <div className="max-w-[1350px] mx-auto flex flex-col w-full justify-center items-center py-12  xl:flex-row lg:flex-row md:flex-row sm:flex fl:flex border-b border-2-b">
        <div className="w-full xl:text-start lg:text-start md:text-start grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 sm:text-center fl:grid-cols-1 fl:text-center  xl:gap-6 lg:gap-6 md:gap-2 sm:gap-2 fl:gap-2">
          <div className="">
          
            <div className="flex flex-col items-center xl:gap-3 lg:gap-3 md:gap-3 sm:gap-1 fl:gap-1 mt-4 xl:px-0 lg:px-0 md:px-2 sm:px-2 fl:px-2">
              <p className="font-bold text-xl xl:text-start lg:text-start md:text-start sm:text-center fl:text-center">Search Bloom LLC – Your SEO Success Partner
              </p>
            
              <p className="text-xs md:px-4 sm:px-4 fl:px-4 xl:px-0 lg:px-2  xl:text-start lg:text-start md:text-start sm:text-center fl:text-center">
              Promote your website with Search Bloom LLC and make it appear on top within weeks. Our SEO products are easy to understand and easy to implement, ensuring that your business ranks higher. <br />
              <br /> Let us make SEO simple and effective for you.
              </p>
            </div>
          </div>
          <div className="md:pl-10">
            <h3 className="text-2xl font-semibold py-6 xl:text-start lg:text-start md:text-start sm:text-center fl:text-center">Quick Links</h3>
            <ul className="xl:list-disc lg:list-disc md:list-disc sm:list-none fl:list-none">
              <li>
                <a href={"/"}>Home</a>
              </li>
              <li>
                <a href={"/about-us"}>About Us</a>
              </li>
              <li>
                <a href={"/book-editing"}>Editing</a>
              </li>
              <li>
                <a href={"/book-publishing"}>Publishing</a>
              </li>
              <li>
                <a href={"/pricing"}>Pricing</a>
              </li>
            </ul>
          </div>
         
          <div className="md:pl-6 md:pr-6">
            <h3 className="text-2xl font-semibold py-6">Non Fiction</h3>
            <ul className="xl:list-disc lg:list-disc md:list-disc sm:list-none fl:list-none">
              <li>
                <a href={"/art-writing"}> Art </a>
              </li>
              <li>
                <a href={"/travel"}> Travel </a>
              </li>
              <li>
                <a href={"/cook-book"}> Cookbook </a>
              </li>
              <li>
                <a href={"/children-book"}> Children</a>
              </li>
              <li>
                <a href={"/education"}> Education </a>
              </li>
            </ul>
          </div>
          <div className="md:pl-6">
            <h3 className="text-2xl font-semibold py-6">About Search Bloom LLC</h3>
            <ul className="xl:list-disc lg:list-disc md:list-disc sm:list-none fl:list-none">
              <li>
                <a href={"/authors"}> Authors </a>
              </li>
              <li>
                <a href={"/pricing"}> Pricing </a>
              </li>
              <li>
                <a href={"/about-us"}> About Us </a>
              </li>
              <li>
                <a href={"/contact-us"}> Contact </a>
              </li>
            </ul>
          </div>
        </div>
      </div> */}

      <div className="flex max-w-[1350px] w-full flex-col md:flex-row py-6  justify-between items-center xl:px-5 lg:px-5 md:px-5 sm:px-2 fl:px-2 ">
        <p className="mx-auto" >© Copyright 2024 SEARCH BLOOM. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
